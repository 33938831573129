<div class="container-fluid">
    <div class="row reorder">
        <div class="col-md-7 col-md-push-5 login-side-banner" id="instruction_section">
            <div class="login-side-banner-child"></div>
            <div class="p-lg-5 text-white">
                <div class="row pt-4 pb-4 border-bottom">
                    <div class="col-md-6">
                        <div class="fw-bold mb-1 text-yellow">INSTRUCTIONS</div>
                        <ul class="no-list-style">
                            <li class="ms-3">
                                <a href="javascript:;" (click)="registraion_info()" class="text-white"><i class="fas fa-arrow-right"></i> &nbsp;Registration Details (UG)</a>
                            </li>
                            <li class="ms-3">
                                <a href="javascript:;" (click)="registraion_pg_info()" class="text-white"><i class="fas fa-arrow-right"></i> &nbsp;Registration Details (PG)</a>
                            </li>
                            <li class="ms-3">
                                <a href="javascript:;" (click)="payment_info()" class="text-white"><i class="fas fa-arrow-right"></i> &nbsp;Payment Information</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <div class="fw-bold mb-1 text-yellow">IMPORTANT DATES</div>
                        <ul class="no-list-style">
                            <li class="ms-3">
                                <a href="javascript:;" (click)="date_info()" class="text-white"><i class="fas fa-arrow-right"></i> &nbsp;Important Dates</a>
                            </li>
                            <li class="ms-3">
                                <a href="javascript:;" (click)="help_info()" class="text-white"><i class="fas fa-arrow-right"></i> &nbsp;Help Desk</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-3 pb-0 ">
                    <!-- <img class="py-3 d-lg-none d-md-none w-100" src="assets/logo.png" alt="DJ Academy Logo"> -->
                    <div class="fw-bold fs-5">Steps for DJAD Admissions 2024 UG & PGRegistration</div>
                    <ol class="tranparent-bg-ol list-group list-group-light list-group-numbered list-group-small mt-2">
                        <li class="list-group-item d-flex justify-content-between align-items-start border-0 text-white">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold text-yellow">Register Yourself</div>
                                <span>Fill the registration form part I</span>
                            </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-start border-0 text-white">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold text-yellow">OTP Verification</div>
                                <span>Enter the OTP & validate mobile no.</span><br>
                                <span>Verify your email id</span>
                            </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-start border-0 text-white">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold text-yellow">Fill Online application form</div>
                                <span>Fill and complete the registration form part II</span><br>
                                <span> Upload Portfolio and other required documents</span>
                            </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-start border-0 text-white">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold text-yellow">Make Payment</div>
                                <span>Make the payment for the application fee</span>
                            </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-start border-0 text-white">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold text-yellow">Preview and Submit</div>
                                <span>Preview the registered details and Submit get acknowledgement by mail</span>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-md-5 col-md-pull-7 login-form" id="login_section">
            <div class="p-3 p-lg-5">
                <div class="pb-4 border-bottom border-info">
                    <img class="pt-4 d-lg-none d-md-none w-100" src="assets/logo.png" alt="DJ Academy Logo">
                    <img class="pt-4 d-none d-md-block d-lg-block" src="assets/logo.png" alt="DJ Academy Logo">
                    <div class="pe-lg-5">
                        <form class="pl-2" (ngSubmit)="submit()" [formGroup]="form">
                            <div class="pt-4 pe-lg-5">
                                <mdb-form-control>
                                    <input mdbInput type="text" formControlName="username" id="username" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="username">DJAD ID / Email ID</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['username'].touched || submitted) && form.controls['username'].errors?.['required']">
                                    Please enter DJAD ID / Email ID
                                </div>
                            </div>
                            <div class="pt-3 pe-lg-5">
                                <mdb-form-control>
                                    <input mdbInput type="password" formControlName="password" id="password" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="password">Password</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['password'].touched || submitted) && form.controls['password'].errors?.['required']">
                                    Please enter password
                                </div>
                            </div>
                            <div class="pt-3">
                                <button type="submit" class="btn btn-primary" [disabled]="isLoginLoading">
                                    <span
                                        *ngIf="isLoginLoading"
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span *ngIf="!isLoginLoading">Sign In</span>
                                </button>
                                <a href="javascript:;" (click)="forgotPassword()" class="ms-3 text-primary">Forgot Password?</a>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="pt-3">
                    <div class="fw-bold">New User? Register Here.</div>
                </div>
                <div class="pt-2">
                    <button type="button" (click)="routeToRegister()" class="btn btn-primary" mdbRipple>Register</button>
                </div>
                <div class="pt-3">
                    <div>DJ Academy of Design is a part of the GKD Charity Trust</div>
                    <div class="small fw-bold pt-3">For Technical Queries:</div>
                    <div class="small fw-bold">Call: 9843073307</div>
                    <div class="small fw-bold">Email: djadadmissions&#64;djad.in</div>
                    <div class="small fw-bold">Between 9.30 AM - 6 PM</div>
                    <div class="small fw-bold">(Monday - Saturday)</div>
                </div>
            </div>
        </div>
    </div>
</div>
