import { Component } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { HelpDeskComponent } from './help-desk/help-desk.component';
import { ImportantDatesComponent } from './important-dates/important-dates.component';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import { RegistrationPgDetailsComponent } from './registration-pg-details/registration-pg-details.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { TermsConditionPgComponent } from './terms-condition-pg/terms-condition-pg.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../utils/services/authentication.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  paymentModalRef: MdbModalRef<PaymentInfoComponent> | null = null;
  helpModalRef: MdbModalRef<HelpDeskComponent> | null = null;
  importantModalRef: MdbModalRef<ImportantDatesComponent> | null = null;
  registrationModalRef: MdbModalRef<RegistrationDetailsComponent> | null = null;
  registrationModalPgRef: MdbModalRef<RegistrationPgDetailsComponent> | null = null;
  termsModalRef: MdbModalRef<TermsConditionComponent> | null = null;
  termsModalPgRef: MdbModalRef<TermsConditionPgComponent> | null = null;
  forgotPasswordModalPgRef: MdbModalRef<ForgotPasswordComponent> | null = null;

  form: FormGroup;
  submitted: boolean = false;

  isLoginLoading: boolean = false;

  constructor(
    private modalService: MdbModalService, 
    private fb: FormBuilder, 
    private authenticationService: AuthenticationService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  registraion_info() {
    this.registrationModalRef = this.modalService.open(RegistrationDetailsComponent, { modalClass: 'modal-lg' })
  }

  registraion_pg_info() {
    this.registrationModalPgRef = this.modalService.open(RegistrationPgDetailsComponent, { modalClass: 'modal-lg' })
  }

  payment_info() {
    this.paymentModalRef = this.modalService.open(PaymentInfoComponent, { modalClass: 'modal-lg' })
  }

  date_info() {
    this.importantModalRef = this.modalService.open(ImportantDatesComponent, { modalClass: 'modal-lg' })
  }

  help_info() {
    this.helpModalRef = this.modalService.open(HelpDeskComponent, { modalClass: 'modal-lg' })
  }

  routeToRegister() {
    this.router.navigate(['/register']);
  }

  terms_condition() {
    this.termsModalRef = this.modalService.open(TermsConditionComponent, { modalClass: 'modal-lg' })
  }

  terms_condition_pg() {
    this.termsModalPgRef = this.modalService.open(TermsConditionPgComponent, { modalClass: 'modal-lg' })
  }

  forgotPassword() {
    this.forgotPasswordModalPgRef = this.modalService.open(ForgotPasswordComponent, { 
      modalClass: 'modal-md',
      keyboard: false,
      ignoreBackdropClick: true 
    });
  }

  submit() {
    this.submitted = true;
    if(this.form.valid) {
      this.isLoginLoading = true;
      let key = {
        username: this.form.controls['username'].value,
        password: this.form.controls['password'].value
      }
      this.authenticationService.login(key).subscribe((res: any) => {
        this.isLoginLoading = false;
        if(res) {
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
          if(res.status && res.statusCode == 200) {
            this.router.navigate(['/']);
          }
        } else {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        }
       }, (err: any) => {
        this.isLoginLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        // console.log(err);
      });
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please Check all Mandatory Fields to Submit', life: 3000 });
    }
  }
}
