<div class="modal-content">
    <div class="modal-header bg-info text-white">
        <h5 class="modal-title">Registration Confirmation</h5>
        <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
    </div>
    <div class="modal-body">
        <p class="text-danger">Please confirm below details, once registered it won't be edited. Once confirmed, click submit to proceed with registration process. OTP will be sent to you registered Email ID.</p>
        <div *ngIf="data" class="">
            <div class="table-responsive">
                <table class="table table-bordered table-hover table-sm table-striped mb-3">
                    <tbody>
                        <tr>
                            <th>Selected Program</th>
                            <th>: {{data.registration_type_name}}</th>
                        </tr>
                        <tr>
                            <th>Discipline</th>
                            <th>: {{data.discipline_name}}</th>
                        </tr>
                        <tr>
                            <th>Salutation</th>
                            <th>: {{data.salutation_name}}</th>
                        </tr>
                        <tr>
                            <th>First Name</th>
                            <th>: {{data.firstname}}</th>
                        </tr>
                        <tr>
                            <th>Middle Name</th>
                            <th>: {{data.middlename}}</th>
                        </tr>
                        <tr>
                            <th>Last Name</th>
                            <th>: {{data.lastname}}</th>
                        </tr>
                        <tr>
                            <th>Date Of Birth</th>
                            <th>: {{data.dob}}</th>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <th>: {{data.gender_name}}</th>
                        </tr>
                        <tr>
                            <th>Category</th>
                            <th>: {{data.category_name}}</th>
                        </tr>
                        <tr>
                            <th>Mobile Number</th>
                            <th>: {{data.mobile}}</th>
                        </tr>
                        <tr>
                            <th>Primary Email</th>
                            <th>: {{data.email}}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="text-center" *ngIf="!otpTriggerd">
                <button class="btn btn-primary me-2" (click)="submit()" [disabled]="isOtpTriggerLoading">
                    <span
                        *ngIf="isOtpTriggerLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span *ngIf="!isOtpTriggerLoading">Submit</span>
                </button>
                <button class="btn btn-danger" (click)="modalRef.close()">Cancel</button>
            </div>
            <div class="my-3" *ngIf="otpTriggerd">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <div class="text-center">Enter the OTP & Verify your email id</div>
                        <form class="pl-2" (ngSubmit)="submitOtp()" [formGroup]="form">
                            <mdb-form-control class="mt-2">
                                <input mdbInput type="text" formControlName="otpValue" id="otpValue" class="form-control form-control-lg" />
                                <label mdbLabel class="form-label" for="otpValue">* OTP</label>
                            </mdb-form-control>
                            <div class="text-danger" *ngIf="(form.controls['otpValue'].touched || otpSubmitted) && (form.controls['otpValue'].errors?.['required'] || form.controls['otpValue'].errors?.['minlength'] || form.controls['otpValue'].errors?.['maxlength'] || form.controls['otpValue'].errors?.['pattern'])">
                                Enter Valid 6 digit OTP
                            </div>
                            <div class="text-left mt-1">
                                <a *ngIf="!isResendOtpTriggerLoading" href="javascript:;" (click)="resendOtp()" class="text-primary">Resend OTP</a>
                                <div *ngIf="isResendOtpTriggerLoading" class="text-success">
                                    Resending OTP
                                    <span
                                        class="spinner-border spinner-border-sm ms-1"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary" [disabled]="isOtpSubmitLoading">
                                    <span
                                        *ngIf="isOtpSubmitLoading"
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span *ngIf="!isOtpSubmitLoading">Submit OTP</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>