import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './utils/guards/auth/auth.guard';
import { nonAuthGuard } from './utils/guards/non-auth/non-auth.guard';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { PaymentSectionComponent } from './components/payment-section/payment-section.component';
import { PreviewDetailsComponent } from './components/preview-details/preview-details.component';
import { FeesSectionComponent } from './components/fees-section/fees-section.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { IndexComponent } from './components/index/index.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [nonAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [nonAuthGuard]
  },
  {
    path: '',
    component: IndexComponent,
    canActivate: [authGuard]
  },
  {
    path: 'personal-details',
    component: PersonalDetailsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'upload-document',
    component: UploadDocumentComponent,
    canActivate: [authGuard]
  },
  {
    path: 'payment-section',
    component: PaymentSectionComponent,
    canActivate: [authGuard]
  },
  {
    path: 'preview-details',
    component: PreviewDetailsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'invoice-section',
    component: FeesSectionComponent,
    canActivate: [authGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
