<div>
  <app-header-nav></app-header-nav>
  <div class="container py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="bg-color-default-theme text-white p-1 ps-2">
          <div class="row">
            <div class="col-md-5 fw-bold">
              {{fullName}}
            </div>
            <div class="col-md-5 fw-bold p-1 ps-2">
              Online Application Form
            </div>
            <div class="col-md-2 fw-bold">
              DJAD ID : {{this.personalData?.djadid}}
            </div>
          </div>
        </div>

        <div class="text-center border border-info p-2 text-success">{{headerText}}</div>
        <div class="bg-color-default-theme text-white p-1 ps-2">Personal Information</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="row">
            <div class="col-md-6">
              <div><span>Salutation: {{salutation}}</span></div>
              <div><span>First Name: {{this.personalData?.firstname}}</span></div>
              <div><span>Middle Name: {{this.personalData?.middlename}}</span></div>
              <div><span>Last Name: {{this.personalData?.lastname}}</span></div>
              <div><span>Email: {{this.personalData?.email}}</span></div>
              <div><span>Gender: {{gender}}</span></div>
            </div>
            <div class="col-md-6">
              <div><span>Date of Birth: {{this.personalData?.dob}}</span></div>
              <div><span>Nationality: {{nationality}}</span></div>
              <div><span>Weight (in Kgs): {{this.personalData?.weight}}</span></div>
              <div><span>Place of Birth: {{this.personalData?.birth_place}}</span></div>
              <div><span>Height (in Cms): {{this.personalData?.height}}</span></div>
              <div><span>Blood Group: {{bloodgroup}}</span></div>
            </div>
          </div>
        </div>
        <div class="bg-color-default-theme text-white p-1 ps-2">Parent/ Guardian Details</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="row">
            <div class="col-md-6">
              <div><span>Name: {{this.personalData?.guardian_name}}</span></div>
              <div><span>Relation with Student: {{guardian_relation}}</span></div>
              <div><span>Annual Income: {{guardian_income}}</span></div>
            </div>
            <div class="col-md-6">
              <div><span>Profession: {{guardian_profession}}</span></div>
              <div><span>Designation: {{this.personalData?.guardian_designation}}</span></div>
            </div>
          </div>
        </div>
        <div class="bg-color-default-theme text-white p-1 ps-2">Address for Correspondence</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="row">
            <div class="col-md-6">
              <div><span>Address Line 1: {{this.personalData?.address_line1}}</span></div>
              <div><span>Address Line 2: {{this.personalData?.address_line2}}</span></div>
              <div><span>Address Line 3: {{this.personalData?.address_line3}}</span></div>
              <div><span>City: {{this.personalData?.city}}</span></div>
              <div><span>State: {{state}}</span></div>
            </div>
            <div class="col-md-6">
              <div><span>Country: {{country}}</span></div>
              <div><span>Pin Code: {{this.personalData?.pincode}}</span></div>
              <div><span>Phone Number: {{this.personalData?.mobile}}</span></div>
              <div><span>Mobile Number: {{this.personalData?.phonenumber}}</span></div>
            </div>
          </div>
        </div>
        <div class="bg-color-default-theme text-white p-1 ps-2">Permanent address</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="row">
            <div class="col-md-6">
              <div><span>Address Line 1: {{this.personalData?.permanent_address_line1}}</span></div>
              <div><span>Address Line 2: {{this.personalData?.permanent_address_line2}}</span></div>
              <div><span>Address Line 3: {{this.personalData?.permanent_address_line3}}</span></div>
              <div><span>City: {{this.personalData?.permanent_city}}</span></div>
            </div>
            <div class="col-md-6">
              <div><span>State: {{permanent_state}}</span></div>
              <div><span>Country: {{permanent_country}}</span></div>
              <div><span>Pin Code: {{this.personalData?.permanent_pincode}}</span></div>
            </div>
          </div>
        </div>
        <div class="bg-color-default-theme text-white p-1 ps-2">Education Information - X std</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="row">
            <div class="col-md-6">
              <div><span>School / College: {{this.personalData?.education1_school}}</span></div>
              <div><span>Board: {{education1_board}}</span></div>
              <div><span>Place: {{this.personalData?.education1_place}}</span></div>
            </div>
            <div class="col-md-6">
              <div><span>Major Subject: {{this.personalData?.education1_subject}}</span></div>
              <div><span>Year Of Passing: {{this.personalData?.education1_yop}}</span></div>
              <div><span>Tenth Percentage (%): {{this.personalData?.education1_percent}}</span></div>
            </div>
          </div>
        </div>
        <div class="bg-color-default-theme text-white p-1 ps-2">Education Information - XII std / Diploma</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="row">
            <div class="col-md-6">
              <div><span>School / College: {{this.personalData?.education2_school}}</span></div>
              <div><span>Stream: {{this.personalData?.education2_stream}}</span></div>
              <div><span>Board: {{education2_board}}</span></div>
              <div><span>Place: {{this.personalData?.education2_place}}</span></div>
            </div>
            <div class="col-md-6">
              <div><span>Major Subject: {{this.personalData?.education2_subject}}</span></div>
              <div><span>Passed / Appearing: {{this.personalData?.education2_status == '1' ? 'Passed' : 'Appearing'}}</span></div>
              <div><span>What exam will you be appearing for at the end of the current academic year?: {{this.personalData?.education2_exam}}</span></div>
              <div><span>In case of discontinuity in academic record, explain your activities during that period: {{this.personalData?.education2_explain}}</span></div>
            </div>
          </div>
        </div>
        <div class="text-center mt-3" *ngIf="enableSubmitBtn">
          <button class="btn btn-success" (click)="submitApn()" [disabled]="isSubmitLoading">
            <span
                *ngIf="isSubmitLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span *ngIf="!isSubmitLoading">Click Here to Submit Application</span>
          </button>
        </div>
        <div class="text-center mt-2">Application Status: <span>{{application_status}}</span></div>
      </div>
    </div>
  </div>
</div>
