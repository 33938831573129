<div>
    <app-header-nav></app-header-nav>
    <div class="container py-4">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="bg-color-default-theme text-white p-1 ps-2">Change Password</div>
                <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                    <div class="text-danger">New Password should satisfy below conditions</div>
                    <ul class="text-danger">
                        <li>At least 8 characters in length</li>
                        <li>Lowercase letters</li>
                        <li>Uppercase letters</li>
                        <li>Numbers</li>
                        <li>Special characters ($, &#64;, $, !, %, *, ?, &)</li>
                    </ul>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <div class="">
                                <form (ngSubmit)="submit()" [formGroup]="form">
                                    <mdb-form-control>
                                        <input mdbInput type="password" formControlName="old_password" id="old_password" class="form-control form-control-lg" />
                                        <label mdbLabel class="form-label" for="old_password">Old Password</label>
                                    </mdb-form-control>
                                    <div class="text-danger" *ngIf="(form.controls['old_password'].touched || submitted) && form.controls['old_password'].errors?.['required']">
                                        Please enter old password
                                    </div>
                                    <mdb-form-control class="mt-3">
                                        <input mdbInput type="password" formControlName="new_password" id="new_password" class="form-control form-control-lg" />
                                        <label mdbLabel class="form-label" for="new_password">New Password</label>
                                    </mdb-form-control>
                                    <div class="text-danger" *ngIf="(form.controls['new_password'].touched || submitted) && form.controls['new_password'].errors?.['required']">
                                        Please enter new password
                                    </div>
                                    <div class="text-danger" *ngIf="(form.controls['new_password'].touched || submitted) && form.controls['new_password'].errors?.['pattern']">
                                        Please make sure password satisfy above conditions
                                    </div>
                                    <mdb-form-control class="mt-3">
                                        <input mdbInput type="password" formControlName="confirm_password" id="confirm_password" class="form-control form-control-lg" />
                                        <label mdbLabel class="form-label" for="confirm_password">Confirm Password</label>
                                    </mdb-form-control>
                                    <div class="text-danger" *ngIf="(form.controls['confirm_password'].touched || submitted) && form.controls['confirm_password'].errors?.['required']">
                                        Please enter confirm password
                                    </div>
                                    <div class="text-danger" *ngIf="(form.controls['confirm_password'].touched || submitted) && (form.controls['confirm_password'].value != form.controls['new_password'].value)">
                                        Password does not Match
                                    </div>
                                    <div class="mt-3 text-center">
                                        <button type="submit" class="btn btn-primary" [disabled]="isSubmitLoading">
                                            <span
                                                *ngIf="isSubmitLoading"
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <span *ngIf="!isSubmitLoading">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-3"></div>
                    </div> 
                </div>       
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>