import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
const backEndUrl = environment.apiUrl + "api/registrations/";
@Injectable({
  providedIn: 'root'
})
export class RegistrationsService {

  constructor(private httpClient: HttpClient) { }

  get() {
    let uniqueParam = Math.random() * 100;
    let url = backEndUrl + "get_personal?cacheBuster="+uniqueParam;
    return this.httpClient.get(url);
  }

  get_document() {
    let uniqueParam = Math.random() * 100;
    let url = backEndUrl + "get_document?cacheBuster="+uniqueParam;
    return this.httpClient.get(url);
  }

  update(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "personal";
    return this.httpClient.post(url, formData);
  }

  add_document(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "document";
    return this.httpClient.post(url, formData);
  }

  delete_document(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "delete_document";
    return this.httpClient.post(url, formData);
  }

  change_password(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "change_password";
    return this.httpClient.post(url, formData);
  }

  submit_application() {
    let url = backEndUrl + "submit_application";
    return this.httpClient.get(url);
  }
}
