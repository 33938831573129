import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-important-dates',
  templateUrl: './important-dates.component.html',
  styleUrl: './important-dates.component.scss'
})
export class ImportantDatesComponent {
  constructor(public modalRef: MdbModalRef<ImportantDatesComponent>) {}
}
