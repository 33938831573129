import { HttpInterceptorFn } from '@angular/common/http';
import { AuthenticationService } from './../services/authentication.service'
import { inject } from "@angular/core";
import { HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

export const sessionHeaderInterceptor: HttpInterceptorFn = (req, next) => {
  var token = null;

  let authenticationService = inject(AuthenticationService);
  let messageService = inject(MessageService);
  
  if(authenticationService.currentUserValue){
    token = authenticationService.currentUserValue?.session_key;
  }

  if(token!== null && token) {
    req = req.clone({
      setHeaders: {
        'Authorization': '' + token
      }
    });
  }


  return next(req).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if(event.body.status === 401) {
          messageService.add({
            severity:'error', 
            summary: 'Unauthorized Access!', 
            detail: 'Please login to proceed!'
          });
          authenticationService.logout();
        } 
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        messageService.add({
          severity:'error', 
          summary: 'Unauthorized Access!', 
          detail: 'Please login to proceed!'
        });
        authenticationService.logout();
      }
      return throwError(error);
    })
  );
};
