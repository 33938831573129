<div class="modal-content">
    <div class="modal-header bg-info text-white">
        <h5 class="modal-title">Forgot Password</h5>
        <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
    </div>
    <div class="modal-body">
        <div class="">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-center">Enter the Registered Email to receive password</div>
                    <form class="pl-2" (ngSubmit)="submitEmail()" [formGroup]="form">
                        <mdb-form-control class="mt-2">
                            <input mdbInput type="email" formControlName="email" id="email" class="form-control form-control-lg" />
                            <label mdbLabel class="form-label" for="otpValue">* Email ID</label>
                        </mdb-form-control>
                        <div class="text-danger" *ngIf="(form.controls['email'].touched || submitted) && form.controls['email'].errors?.['required']">
                            Email is required
                        </div>
                        <div class="text-danger" *ngIf="(form.controls['email'].touched || submitted) && (form.controls['email'].errors?.['email'] || form.controls['email'].errors?.['pattern'])">
                            Please provide valid Email
                        </div>
                        <div class="mt-3 text-center">
                            <button type="submit" class="btn btn-primary" [disabled]="isSubmitLoading">
                                <span
                                    *ngIf="isSubmitLoading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span *ngIf="!isSubmitLoading">Submit</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>  