<div>
    <app-header-nav></app-header-nav>
    <div class="container py-4">
        <div class="row">
            <form (ngSubmit)="submit($event)" [formGroup]="form">
                <div class="col-md-12">
                    <app-steps></app-steps>
                    <div class="text-center border border-info p-2">{{ headerText }}</div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Discipline offered</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info ">
                        <span class="small font-color-default-theme">Note: Choice of discipline versus your aptitude could be discussed and your choice of discipline could be altered during the interview process if you feel so.</span>
                        <div class="row pt-2">
                            <div class="col-md-2"></div>
                            <div class="col-md-7">
                                <div>
                                    * Select Program Preference
                                    <div class="ps-5 py-2 mb-1">
                                        <div *ngFor="let course_group of course_groups" class="form-check form-check-inline">
                                            <input mdbRadio class="form-check-input" type="radio" formControlName="registration_type" id="registration_type-{{course_group['group_id']}}" [value]="course_group['group_id']" />
                                            <label class="form-check-label" for="registration_type-{{course_group['group_id']}}">{{course_group['group_name']}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="form.controls['registration_type'].value">
                                    * Choose one Discipline
                                    <div class="ps-5 pt-2 mb-1">
                                        <div *ngFor="let discipline of disciplines" class="form-check">
                                            <input mdbRadio class="form-check-input" type="radio" formControlName="discipline" [value]="discipline['id']" id="discipline-{{discipline['id']}}" />
                                            <label class="form-check-label" for="discipline">{{discipline['description']}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Personal Information</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-3">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="salutation" id="salutation" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let salutation of salutations" [value]="salutation['id']">{{salutation['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="salutation">* Salutation</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="firstname" id="firstname" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="firstname">* First Name</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="middlename" id="middlename" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="middlename">Middle Name</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="lastname" id="lastname" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="lastname">* Last Name</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="date" formControlName="dob" id="dob" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="dob">* Date Of Birth</label>
                                </mdb-form-control>
                                <div class="mt-3">
                                    <span class="me-4">* Gender</span>
                                    <div class="ps-5 pt-2">
                                        <div *ngFor="let gender of genders" class="form-check form-check-inline">
                                            <input mdbRadio class="form-check-input" type="radio" formControlName="gender" id="gender-{{gender['id']}}" [value]="gender['id']" />
                                            <label class="form-check-label" for="gender">{{gender['name']}}</label>
                                        </div>
                                    </div>
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="category" id="category" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let category of categories" [value]="category['id']">{{category['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="category">* Category</label>
                                </mdb-form-control>
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="email" formControlName="email" id="email" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="email">* Primary Email</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="confirm_email" id="confirm_email"  class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="confirm_email">* Retype Primary Email</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="nationality" id="nationality" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let nation of nations" [value]="nation['country_id']">{{nation['short_name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="nationality">* Nationality</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['nationality'].touched || submitted) && form.controls['nationality'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="number" formControlName="weight" id="weight" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="weight">Weight (in Kgs)</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="birth_place" id="birth_place" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="birth_place">* Place of Birth</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['birth_place'].touched || submitted) && form.controls['birth_place'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="number" formControlName="height" id="height" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="height">Height (in cms)</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="bloodgroup" id="bloodgroup" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let group of blood_groups" [value]="group['id']">{{group['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="bloodgroup">Blood Group</label>
                                </mdb-form-control>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Parent / Guardian Details</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-3">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="guardian_name" id="guardian_name" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="guardian_name">* Name</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['guardian_name'].touched || submitted) && form.controls['guardian_name'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="guardian_relation" id="guardian_relation" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let relation of relations" [value]="relation['id']">{{relation['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="guardian_relation">* Relation with Student</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['guardian_relation'].touched || submitted) && form.controls['guardian_relation'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="guardian_income" id="guardian_income" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let income of annualincomes" [value]="income['id']">{{income['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="guardian_income">* Annual Income</label>
                                </mdb-form-control> 
                                <div class="text-danger" *ngIf="(form.controls['guardian_income'].touched || submitted) && form.controls['guardian_income'].errors?.['required']">
                                    The above field is required field
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="guardian_profession" id="guardian_profession" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let profession of professions" [value]="profession['id']">{{profession['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="guardian_profession">* Profession</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['guardian_profession'].touched || submitted) && form.controls['guardian_profession'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="guardian_designation" id="guardian_designation" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="guardian_designation">* Designation</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['guardian_designation'].touched || submitted) && form.controls['guardian_designation'].errors?.['required']">
                                    The above field is required field
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Address for Correspondence</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-3">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="address_line1" id="address_line1" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="address_line1">* Address Line 1</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['address_line1'].touched || submitted) && form.controls['address_line1'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="address_line2" id="address_line2" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="address_line2">Address Line 2</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="address_line3" id="address_line3" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="address_line3">Address Line 3</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="country" id="country" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let nation of nations" [value]="nation['country_id']">{{nation['short_name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="country">* Country</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['country'].touched || submitted) && form.controls['country'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="state" id="state" class="form-control form-control-lg">
                                        <option value="" disabled selected>Select State</option>
                                        <option *ngFor="let state of corres_states" [value]="state['id']">{{state['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="state">* State</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['state'].touched || submitted) && form.controls['state'].errors?.['required']">
                                    The above field is required field
                                </div>
                                
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="city" id="city" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="city">* City</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['city'].touched || submitted) && form.controls['city'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="number" formControlName="pincode" id="pincode" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="pincode">* Pin Code</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['pincode'].touched || submitted) && form.controls['pincode'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="mobile" id="mobile" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="mobile">* Mobile Number</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="phonenumber" id="phonenumber" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="phonenumber">* Phone Number</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['phonenumber'].touched || submitted) && form.controls['phonenumber'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['phonenumber'].touched || submitted) && (form.controls['phonenumber'].errors?.['minlength'] || form.controls['phonenumber'].errors?.['maxlength'])">
                                    Please enter valid 10 Digit Phone Number
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['phonenumber'].touched || submitted) && form.controls['phonenumber'].errors?.['pattern']">
                                    Please enter valid Phone Number
                                </div>
                                <div class="mt-4">
                                    <span class="me-4">* Is permanent address same as above?</span>
                                    <div class="form-check form-check-inline">
                                        <input mdbRadio class="form-check-input" formControlName="address_same" type="radio" id="address_same1" value="1" />
                                        <label class="form-check-label" for="address_same1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input mdbRadio class="form-check-input" formControlName="address_same" type="radio" id="address_same2" value="2" />
                                        <label class="form-check-label" for="address_same2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="form.controls['address_same'].value == 2" class="bg-color-default-theme text-white p-1 ps-2">Permanent address</div>
                    <div *ngIf="form.controls['address_same'].value == 2" class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-3">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="permanent_address_line1" id="permanent_address_line1" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="permanent_address_line1">* Address Line 1</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['permanent_address_line1'].touched || submitted) && form.controls['permanent_address_line1'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="permanent_address_line2" id="permanent_address_line2" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="permanent_address_line2">Address Line 2</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="permanent_address_line3" id="permanent_address_line3" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="permanent_address_line3">Address Line 3</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="permanent_country" id="permanent_country" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let nation of nations" [value]="nation['country_id']">{{nation['short_name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="permanent_country">* Country</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['permanent_country'].touched || submitted) && form.controls['permanent_country'].errors?.['required']">
                                    The above field is required field
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="permanent_state" id="permanent_state" class="form-control form-control-lg">
                                        <option value="" disabled selected>Select State</option>
                                        <option *ngFor="let state of permanent_states" [value]="state['id']">{{state['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="permanent_state">* State</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['permanent_state'].touched || submitted) && form.controls['permanent_state'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="permanent_city" id="permanent_city" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="permanent_city">* City</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['permanent_city'].touched || submitted) && form.controls['permanent_city'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="number" formControlName="permanent_pincode" id="permanent_pincode" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="permanent_pincode">* Pin Code</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['permanent_pincode'].touched || submitted) && form.controls['permanent_pincode'].errors?.['required']">
                                    The above field is required field
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Education Information - X std</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-3">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education1_school" id="education1_school" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education1_school">* School / College</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education1_school'].touched || submitted) && form.controls['education1_school'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="education1_board" id="education1_board" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let board of boards" [value]="board['id']">{{board['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="education1_board">* Board</label>
                                </mdb-form-control>  
                                <div class="text-danger" *ngIf="(form.controls['education1_board'].touched || submitted) && form.controls['education1_board'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education1_place" id="education1_place" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education1_place">* Place</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education1_place'].touched || submitted) && form.controls['education1_place'].errors?.['required']">
                                    The above field is required field
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education1_subject" id="education1_subject" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education1_subject">* Major Subject</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education1_subject'].touched || submitted) && form.controls['education1_subject'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="education1_yop" id="education1_yop" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let year of years" [value]="year">{{year}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="education1_yop">* Year Of Passing</label>
                                </mdb-form-control>  
                                <div class="text-danger" *ngIf="(form.controls['education1_yop'].touched || submitted) && form.controls['education1_yop'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="number" formControlName="education1_percent" id="education1_percent" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education1_percent">* Tenth Percentage (%)</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education1_percent'].touched || submitted) && form.controls['education1_percent'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['education1_percent'].touched || submitted) && (form.controls['education1_percent'].errors?.['min'] || form.controls['education1_percent'].errors?.['max'])">
                                    Please enter valid input
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Education Information - XII std / Diploma</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-3">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education2_school" id="education2_school" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education2_school">* School / College</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education2_school'].touched || submitted) && form.controls['education2_school'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education2_stream" id="education2_stream" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education2_stream">* Stream</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education2_stream'].touched || submitted) && form.controls['education2_stream'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="education2_board" id="education2_board" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let board of boards" [value]="board['id']">{{board['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="education2_board">* Board</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education2_board'].touched || submitted) && form.controls['education2_board'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <div class="mt-3">What exam will you be appearing for at the end of the current academic year?</div>
                                <mdb-form-control class="mt-2">
                                    <textarea mdbInput formControlName="education2_exam" id="education2_exam" class="form-control form-control-lg" rows="2"></textarea>
                                    <!-- <label mdbLabel class="form-label" for="education2_exam">What exam will you be appearing for at the end of the current academic year?</label> -->
                                </mdb-form-control>
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education2_place" id="education2_place" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education2_place">* Place</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education2_place'].touched || submitted) && form.controls['education2_place'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <input mdbInput type="text" formControlName="education2_subject" id="education2_subject" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="education2_subject">* Major Subject</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education2_subject'].touched || submitted) && form.controls['education2_subject'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <mdb-form-control class="mt-3">
                                    <select mdbInput formControlName="education2_status" id="education2_status" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option value="1">Passed</option>
                                        <option value="2">Appearing</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="education2_status">* Passed / Appearing</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['education2_status'].touched || submitted) && form.controls['education2_status'].errors?.['required']">
                                    The above field is required field
                                </div>
                                <div class="mt-3">In case of discontinuity in academic record, explain your activities during that period</div>
                                <mdb-form-control class="mt-2">
                                    <textarea mdbInput formControlName="education2_explain" id="education2_explain" class="form-control form-control-lg" rows="2"></textarea>
                                    <!-- <label mdbLabel class="form-label" for="education2_explain">In case of discontinuity in academic record, explain your activities during that period</label> -->
                                </mdb-form-control>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">How did you come to know about D. J. Academy of Design?</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row pb-4">
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <textarea mdbInput type="text" formControlName="website_lead" id="website_lead" class="form-control form-control-lg" rows="2"></textarea>
                                    <label mdbLabel class="form-label" for="website_lead">If through website, specify</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <textarea mdbInput type="text" formControlName="newspaper_lead" id="newspaper_lead" class="form-control form-control-lg" rows="2"></textarea>
                                    <label mdbLabel class="form-label" for="newspaper_lead">If through Newspaper, specify</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <textarea mdbInput type="text" formControlName="wom_lead" id="wom_lead" class="form-control form-control-lg" rows="2"></textarea>
                                    <label mdbLabel class="form-label" for="wom_lead">If through word of mouth, specify person name</label>
                                </mdb-form-control>
                            </div>
                            <div class="col-md-6">
                                <mdb-form-control class="mt-3">
                                    <textarea mdbInput type="text" formControlName="other_lead" id="other_lead" class="form-control form-control-lg" rows="2"></textarea>
                                    <label mdbLabel class="form-label" for="other_lead">Others specify</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3">
                                    <textarea mdbInput type="text" formControlName="coaching_lead" id="coaching_lead" class="form-control form-control-lg" rows="2"></textarea>
                                    <label mdbLabel class="form-label" for="coaching_lead">If through Coaching institute, Specify</label>
                                </mdb-form-control>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Upload your current passport size photo (not more than 3 months old) and your signature</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <span class="small font-color-default-theme">The upload photo should be in .jpg, .jpeg, .png or .gif formats only and the photo size should be between 10 KB and 150 KB.</span>
                        <div class="row mt-2">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <div>
                                    <label for="profile_photo" class="form-label">Upload photo</label>
                                    <input (change)="profilePicChange($event)" class="form-control" formControlName="profile_photo" id="profile_photo" type="file" />
                                    <div class="mt-3">
                                        <div>Preview:</div>
                                        <img class="w-full ms-3 mt-2" height="150" src="{{profilePicPreview}}" alt="">
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <label for="sign_photo" class="form-label">Upload Signature</label>
                                    <input (change)="signPicChange($event)" class="form-control" formControlName="sign_photo" id="sign_photo" type="file" />
                                    <div class="mt-3">
                                        <div>Preview:</div>
                                        <img class="w-full ms-3 mt-2" height="150" src="{{SignPicPreview}}" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <button id="saveAndcontinue" type="submit" class="btn btn-primary me-3" [disabled]="isPersonalLoading">
                            <span
                                *ngIf="isPersonalLoading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span *ngIf="!isPersonalLoading">Save and Continue</span>
                        </button>
                        <button id="onlySave" type="submit" class="btn btn-primary" [disabled]="isPersonalLoading">
                            <span
                                *ngIf="isPersonalLoading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span *ngIf="!isPersonalLoading">Save</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>