import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-registration-pg-details',
  templateUrl: './registration-pg-details.component.html',
  styleUrl: './registration-pg-details.component.scss'
})
export class RegistrationPgDetailsComponent {
  constructor(public modalRef: MdbModalRef<RegistrationPgDetailsComponent>) {}
}
