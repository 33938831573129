<div class="modal-content modal-xxl">
  <div class="modal-header bg-info text-light">
    <h5 class="modal-title">Terms and Conditions</h5>
    <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="">
      <h6 class="text-danger mb-4">IMPORTANT: Before initiating the online registration, please ensure that you go to your browser settings and TURN OFF the auto fill option to avoid incorrect information being entered.</h6>
      <p>
        <strong><u>General Information</u></strong>
      </p>
      <p>DJAD B.Des (Undergraduate Programme) is collaborated with Alagappa University, Tamil Nadu.</p>
      <p><strong>Programmes offered are</strong></p>
      <ol>
        <li>B.Des Industrial Design (Includes the design domains viz. Product Design, Transportation Design, Furniture and Space Design, Jewellery Design, Ceramic and Glass Design, Toy and Game Design, Universal Design etc.)</li>
        <li>B.Des Communication Design (Includes the design domains viz. Graphic Design, Animation and Video Film Design, Photography Design, UI/UX, Digital Game Design, Immersive Media Design etc.)</li>
        <li>B. Des User Experience Design (Usability Testing, Information Architecture, Application Design, Interface, Interaction Design etc.)</li>
        <li>B. Des Animation & Film Making (Video Film Making, Animation Film Making, Cinematography, Lighting, Editing, VFX etc.)</li>
        <li>B.Des Interior Design (Space Design, Furniture Design etc.)</li>
      </ol>
      <p>Admission is offered to candidates, based on their performance in the Aptitude Test (DEED 2024) and Personal Interview conducted for assessing their aptitude in Design.</p>
      <p>
        <strong><u>Eligibility Criteria</u></strong>
      </p>
      <p>Eligibility</p>
      <ol>
        <li>
          Candidates should have passed 12th standard or its equivalent from any stream from any recognised State Board or Central Board like CBSE,ICSE etc. with an aggregate minimum of 50% marks (OR) Candidates should have passed a three
          year Diploma with 50% aggregate marks (after 10th Std) from any stream, from an institution recognised by AICTE/State Govt.
        </li>
        <li>Candidates should have passed a three year Diploma with 50% aggregate marks (after 10th Std) from any stream, from an institution recognised by AICTE/State Govt.</li>
        <li>Those who are appearing/appeared for these qualifying examinations before July of the year of applying to B.Des. can also apply.</li>
        <li class="mb-4">Contact 9843073307 before applying if your qualification is not among the above mentioned details.</li>
      </ol>
      <p>
        <u><strong>Age</strong></u>
      </p>
      <ol>
        <li>Up to 22 years as on 31st July 2024</li>
      </ol>
      <p>
        <strong><u>Registration Instructions</u></strong>
      </p>
      <ol>
        <li>Please ensure availability of the soft copy of your 10th/SSC and 12th/HSC marksheets and passport size photo in jpg/jpeg/png format. (File size should not exceed 150kb each.)</li>
        <li>Please choose "Appearing" in case you are appearing for the 12th standard exam and/or awaiting results this year.</li>
        <li>Click on "Register"</li>
        <li>Read the Instructions carefully and then click on the 'I Agree' button to accept the Terms and Conditions.</li>
        <li>Fill in the details requested on the page displayed.</li>
        <li>Click on "Register". An OTP will be sent to the mobile number and email id that you have entered.</li>
        <li>Enter the 'OTP' and submit.</li>
        <li>Fill the remaining application form.</li>
        <li>Then click on "Save & Continue" to register and upload Portfolio.</li>
        <li>Make a non - refundable payment of Rs. 1,500/ towards Application Fee, Admission 2024.</li>
        <li>After registration, you will get a registration email containing your DJAD ID and password.</li>
      </ol>
      <p>
        <strong><u>Payment Information</u></strong>
      </p>
      <ol>
        <li>Pay a fee of Rs.1,500/-</li>
        <li>You have the following option for payment mode.</li>
        <li>ONLINE PAYMENT THROUGH CCAVENUE PAYMENT GATEWAY</li>
      </ol>
      <!-- <p>
        <strong><u>Admit Card Instructions</u></strong>
      </p>
      <div class="mb-3 form-check">
        <input type="checkbox" class="form-check-input" id="terms" />
        <label class="form-check-label" for="terms">If you agree to the above terms and conditions, click "I agree" and please complete your form by filling all the parts of your application.</label>
      </div>
      <button type="submit" class="btn btn-success agree">I Agree</button>
      <button type="submit" class="btn btn-danger">I Disagree</button> -->
    </div>
  </div>
  <div class="modal-footer justify-center-login">
    <button type="button" class="btn btn-danger" (click)="modalRef.close()">Close</button>
  </div>
</div>
