<div>
    <app-header-nav></app-header-nav>
    <div class="container py-4">
      <div class="row">
        <div class="col-md-12">
          <div class="bg-color-default-theme text-white p-1 ps-2 border border-info">Credit/Debit Cards/Internet Banking:</div>
          <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
            <div class="text-end mt-4 mb-3">
              <button class="d-none btn btn-primary me-3">Print Preview</button>
              <button class="d-none btn btn-primary me-3">View Payment Instructions</button>
            </div>
            <div class="text-center">
              <h2>DJAD {{currentUser.registration_type == 1 ? "UG" : "PG"}} Fees History</h2>
              <p>Payment through Credit/Debit Cards/Internet Banking</p>
              <p>(CCAvenue Payment Gateway)</p>
              <p class="text-danger" *ngIf="isPaymentPending">For Transaction Status <b>Initiated / Pending</b>, Please check after few minutes. while we complete your payment status checking process...</p>
            </div>
            <div class="table-responsive mt-3" *ngIf="invoiceLists && invoiceLists.length > 0">
              <table class="table table-sm table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <!-- <th>#</th> -->
                    <th>#</th>
                    <th>Fee Category</th>
                    <th>Due Date</th>
                    <th>Amount (Rs)</th>
                    <th>Penalty Amount (Rs)</th>
                    <th>Total Amount (Rs)</th>
                    <th>To Pay (Rs)</th>
                    <th>Status</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Add table rows here -->
                  <tr *ngFor="let invoice of invoiceLists; index as i">
                    <!-- <td>{{ i+1 }}</td> -->
                    <td>{{invoice.formatinvoiceid}}</td>
                    <td>{{invoice.category_name}}</td>
                    <td>{{invoice.duedate}}</td>
                    <td>{{invoice.subtotal}}</td>
                    <td>{{invoice.fine_amount || '0.00'}}</td>
                    <td>{{invoice.total}}</td>
                    <td>{{invoice.left_to_pay}}</td>
                    <td>{{invoice.status_name}}</td>
                    <td>
                        <button *ngIf="invoice.status != 2 && invoice.payment_status != 1 && invoice.payment_status != 2" class="btn btn-success btn-sm me-2" (click)="initiatePayment(i)" [disabled]="invoice.isPaymentLoading">
                            <span
                                *ngIf="invoice.isPaymentLoading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span *ngIf="!invoice.isPaymentLoading">Pay</span>
                        </button>
                        <button *ngIf="invoice.paymentattempts.length > 0" (click)="openHistoryModal(i)" class="btn btn-info btn-sm me-2"><i class="fa fa-history" aria-hidden="true"></i></button>
                        <a target="_blank" href="{{apiUrl}}/invoice/{{invoice.id}}/{{invoice.hash}}" class="btn btn-secondary btn-sm"><i class="fa fa-file" aria-hidden="true"></i></a>
                        <div class="mt-2" *ngIf="invoice.payment_status">Last Trans.: {{invoice.payment_status_name}}</div>
                    </td>
                  </tr>
                  <!-- Add more rows as needed -->
                </tbody>
              </table>
            </div>
            
            <form #form ngNoForm
              id="nonseamless" 
              method="post" 
              name="redirect" 
              action="{{formAction}}">
                <input type="hidden" id="encRequest" name="encRequest" value="{{encryptedData}}">
                <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  