import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MastersService } from '../../utils/services/masters.service';
import { RegistrationsService } from '../../utils/services/registrations.service';
import { AuthenticationService } from '../../utils/services/authentication.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrl: './upload-document.component.scss'
})
export class UploadDocumentComponent {

  currentUser: any = null;
  currentUserSub: any = null;
  form: FormGroup;
  masters: any;
  fileObject = null;
  submitted = false;
  isDocumentSubmitLoading = false;
  documentLists: any = [];
  types = [];
  
  constructor(
    private fb: FormBuilder, 
    private mastersService: MastersService,
    private registrationService: RegistrationsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router
  ) { 

    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      if(data) {
        this.currentUser = data;
        if(this.currentUser?.is_personal_completed != 1) {
          this.router.navigate(['/personal-details']);
          this.messageService.add({ severity: 'error', detail: 'Please complete personal details section to continue further.', life: 3000 });
        }
      }
    });

    this.getMasters();

    this.form = this.fb.group({
      document_type: ['', Validators.required],
      attachment: ['', Validators.required]
    });
  }

  getMasters() {
    this.mastersService.read().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.masters = res.data;
        this.types = this.masters.registration_document_types;
        this.getDocumentLists();
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  parseDocuments(documents: any) {
    for(let i=0;i<documents.length;i++) {
      documents[i]['document_type_name'] = this.mastersService.get_corresponding_name(this.types, documents[i]['document_type']);
      documents[i]['document_download_link'] = environment.apiUrl + "/uploads/registrations/" + documents[i].registrationid + "/" + documents[i].document_url;
    }
    return documents;
  }

  getDocumentLists() {
    this.registrationService.get_document().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.documentLists = this.parseDocuments(res.documents);
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  documentChange(event: any) {
    this.fileObject = null;
    let fileSrc = event.target.files[0];
    if(fileSrc.type == 'application/pdf') {
      if(fileSrc.size <= 2097152) {
        this.fileObject = fileSrc;
      } else {
        this.messageService.add({ severity: 'error', detail: 'Please upload file less than 2MB', life: 3000 });
        this.form.controls['attachment'].setValue("");
        this.form.controls['attachment'].markAsUntouched();
      }
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please upload only PDF file.', life: 3000 });
      this.form.controls['attachment'].setValue("");
      this.form.controls['attachment'].markAsUntouched();
    }
  }

  redirectToPayment() {
    if(this.currentUser?.is_document_completed == 1) {
      this.router.navigate(['/payment-section']);
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please upload all the required document to proceed further.', life: 3000 });
    }
  }

  submit(event: any) {
    this.submitted = true;
    if(this.currentUser.is_submitted == 0) {
      if(this.form.valid && this.fileObject) {
        this.isDocumentSubmitLoading = true;
        let data: any = {
          document_type: this.form.controls['document_type'].value,
          attachment: this.fileObject
        };
        data.registrationid = this.currentUser?.registrationid;
        this.registrationService.add_document(data).subscribe((res: any) => {
          // console.log(res);
          this.isDocumentSubmitLoading = false;
          if(res) {
            this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
            if(res.status && res.statusCode == 200) {
              this.submitted = false;
              this.form.reset();
              this.form.controls['document_type'].setValue("");
              this.form.controls['attachment'].setValue("");
              this.form.controls['document_type'].markAsUntouched;
              this.form.controls['attachment'].markAsUntouched;
              this.documentLists = this.parseDocuments(res.documents);
              this.authenticationService.setCurrentUserValue(res.user_data);
              localStorage.setItem("currentUser", JSON.stringify(res.user_data));
              if(event.submitter.id == "saveAndcontinue") {
                if(res.user_data?.is_document_completed == 1) {
                  setTimeout(() => {
                    this.redirectToPayment();
                  }, 1000);
                } else {
                  this.messageService.add({ severity: 'error', detail: 'Please upload all mandatory documents to proceed further', life: 3000 });
                }
              }
            }
          } else {
            this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
          }
         }, (err: any) => {
          this.isDocumentSubmitLoading = false;
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
          // console.log(err);
        });
      } else {
        this.messageService.add({ severity: 'error', detail: 'Please Check all Mandatory Fields to Submit', life: 3000 });
      }
    } else {
      this.messageService.add({ severity: 'error', detail: 'Not Allowed to perform action, Application Already Submitted.', life: 3000 });
    }
  }

  delete_document(documentid: any, type: any) {
    if(this.currentUser.is_submitted == 0) {
      if(confirm("Are you sure you want to remove the " + type + " document?")) {
        let data = {documentid};
        this.registrationService.delete_document(data).subscribe((res: any) => {
          if(res) {
            this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
            if(res.status && res.statusCode == 200) {
              this.documentLists = this.parseDocuments(res.documents);
              this.authenticationService.setCurrentUserValue(res.user_data);
              localStorage.setItem("currentUser", JSON.stringify(res.user_data));
            }
          } else {
            this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
          }
        }, (err: any) => {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
          // console.log(err);
        });
      }
    } else {
      this.messageService.add({ severity: 'error', detail: 'Not Allowed to perform action, Application Already Submitted.', life: 3000 });
    }
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}
