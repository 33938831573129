<div class="modal-content">
  <div class="modal-header bg-info text-white">
    <h5 class="modal-title">Payment Information</h5>
    <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="">
      <p>Pay a fee of Rs.1,500/-</p>
      <p>You have the following option for payment mode.</p>
      <h6>ONLINE PAYMENT THROUGH CCAVENUE PAYMENT GATEWAY</h6>
    </div>
  </div>
  <div class="modal-footer justify-center-login">
    <button type="button" class="btn btn-danger" (click)="modalRef.close()">Close</button>
  </div>
</div>
