<div class="modal-content">
    <div class="modal-header bg-info text-white">
        <h5 class="modal-title">Registration Instructions:</h5>
        <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
    </div>
    <div class="modal-body">
        <div class="">
          <ol>
            <li>Please ensure availability of the soft copy of your 10th/SSC and 12th/HSC marksheets and passport size photo in jpg/jpeg/png format. (File size should not exceed 150kb each.)</li>
            <li>Please choose "Appearing" in case you are appearing for the 12th standard exam and/or awaiting results this year.</li>
            <li>Click on "Register"</li>
            <li>Read the Instructions carefully and then click on the 'I Agree' button to accept the Terms and Conditions.</li>
            <li>Fill in the details requested on the page displayed.</li>
            <li>Click on "Register". An OTP will be sent to the mobile number and email id that you have entered.</li>
            <li>Enter the 'OTP' and submit.</li>
            <li>Fill the remaining application form.</li>
            <li>Then click on "Save & Continue" to register and upload Portfolio.</li>
            <li>Make a non - refundable payment of Rs. 1,500/ towards Application Fee, Admission 2024.</li>
            <li>After registration, you will get a registration email containing your DJAD ID and password.</li>
          </ol>
        </div>
    </div>
    <div class="modal-footer justify-center-login">
        <button type="button" class="btn btn-danger" (click)="modalRef.close()">Close</button>
    </div>
</div>
