import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './../models/user.model';
import { Auth } from './../models/auth.model';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { environment } from '../../../environments/environment';
const backEndUrl = environment.apiUrl + "api/authentication/";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(
    private httpClient: HttpClient, 
    private router: Router, 
    @Inject(DOCUMENT) private document: Document
  ) {
    const localStorage = document.defaultView?.localStorage;
    if(localStorage) {
      let tempUser = localStorage.getItem('currentUser');
      this.currentUserSubject = new BehaviorSubject<User | null>((tempUser ? JSON.parse(tempUser) : null));
    } else {
      this.currentUserSubject = new BehaviorSubject<User | null>(null);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(user_data: User) {
    this.currentUserSubject.next(user_data);
  }

  login(data: any) {
    data['login_option'] = 1;
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "login";
    // return this.httpClient.post(url, formData);
    return this.httpClient.post<Auth>(url, formData).pipe(
      map((res: Auth) => {
        if(res.status) {
          localStorage.setItem("currentUser", JSON.stringify(res.user_data));
          this.currentUserSubject.next(res.user_data);
        }
        return res;
      })
    )
  }

  register(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "register";
    return this.httpClient.post(url, formData);
  }

  verify_otp(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "verify_otp";
    // return this.httpClient.post(url, formData);
    return this.httpClient.post<Auth>(url, formData).pipe(
      map((res: Auth) => {
        if(res.status) {
          localStorage.setItem("currentUser", JSON.stringify(res.user_data));
          this.currentUserSubject.next(res.user_data);
        }
        return res;
      })
    )
  }

  resend_otp(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "resend_otp";
    return this.httpClient.post(url, formData);
  }

  forgot_password(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "forgot_password";
    return this.httpClient.post(url, formData);
  }

  toHome() {
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}
