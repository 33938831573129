<nav class="navbar navbar-dark bg-dark">
    <div class="container justify-content-between">
        <div class="d-flex">
            <a routerLink="/"><img class="nav-logo w-100" src="assets/logo.png" alt="DJ Academy Logo"></a>
        </div>
        <ul *ngIf="showHeaderNav" class="navbar-nav flex-row mt-3 mt-md-0">
            <li>
                <img
                    [src]="profilePicUrl"
                    class="rounded-circle"
                    height="50"
                    width="50"
                    alt=""
                    loading="lazy"
                />
            </li>
            <li mdbDropdown #dropdown class="nav-item dropdown"> 
                <small class="ms-2 text-white">DJAD ID - {{currentUser.djadid}}</small>
                <a 
                    class="nav-link dropdown-toggle d-sm-flex align-items-sm-center p-0 text-white" 
                    href="javascript:;" 
                    id="navbarDropdownMenuLink"
                    role="button"
                    mdbDropdownToggle
                    aria-expanded="false"
                >
                    <strong class="ms-2">Hi, {{currentUser.firstname}} {{currentUser.lastname}}</strong>
                </a>
                <ul
                    mdbDropdownMenu
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdownMenuLink"
                >
                    <li>
                        <a class="dropdown-item" href="javascript:;" (click)="goToForm()">Application Form</a>
                    </li>
                    <li class="d-none">
                        <a class="dropdown-item" href="#">Application Status</a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="/preview-details">Application Preview</a>
                    </li>
                    <li *ngIf="currentUser.is_converted == 1">
                        <a class="dropdown-item" routerLink="/invoice-section">Fee Section</a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="/change-password">Change Password</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="javascript:;" (click)="logout()">Logout</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>