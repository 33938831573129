import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';

import { ToastModule } from 'primeng/toast';
import { StepsModule } from 'primeng/steps';

import { LoginComponent } from './components/login/login.component';
import { PaymentInfoComponent } from './components/login/payment-info/payment-info.component';
import { RegistrationDetailsComponent } from './components/login/registration-details/registration-details.component';
import { ImportantDatesComponent } from './components/login/important-dates/important-dates.component';
import { HelpDeskComponent } from './components/login/help-desk/help-desk.component';
import { TermsConditionComponent } from './components/login/terms-condition/terms-condition.component';
import { RegisterComponent } from './components/register/register.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { PaymentSectionComponent } from './components/payment-section/payment-section.component';
import { PreviewDetailsComponent } from './components/preview-details/preview-details.component';
import { RegistrationPgDetailsComponent } from './components/login/registration-pg-details/registration-pg-details.component';
import { TermsConditionPgComponent } from './components/login/terms-condition-pg/terms-condition-pg.component';
import { RegisterConfirmComponent } from './components/register/register-confirm/register-confirm.component';
import { IndexComponent } from './components/index/index.component';

import { sessionHeaderInterceptor } from './utils/interceptors/session-header.interceptor';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { StepsComponent } from './components/steps/steps.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FeesSectionComponent } from './components/fees-section/fees-section.component';
import { PaymentHistoryComponent } from './components/fees-section/payment-history/payment-history.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PaymentInfoComponent,
    RegistrationDetailsComponent,
    ImportantDatesComponent,
    HelpDeskComponent,
    TermsConditionComponent,
    RegisterComponent,
    HeaderNavComponent,
    PersonalDetailsComponent,
    UploadDocumentComponent,
    PaymentSectionComponent,
    PreviewDetailsComponent,
    RegistrationPgDetailsComponent,
    TermsConditionPgComponent,
    RegisterConfirmComponent,
    IndexComponent,
    StepsComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    FeesSectionComponent,
    PaymentHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MdbFormsModule,
    MdbModalModule,
    MdbDropdownModule,
    MdbRippleModule,
    MdbCheckboxModule,
    ToastModule,
    StepsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    MessageService,
    provideClientHydration(),
    provideHttpClient(withInterceptors([sessionHeaderInterceptor])),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
