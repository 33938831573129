<div class="modal-content">
  <div class="modal-header bg-info text-white">
    <h5 class="modal-title">FAQ on how to apply you can call up?</h5>
    <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="">
      <p>Call: <a href="tel:9843073307">98430 73307</a></p>
      <p>Email: <a href="mailto:djadadmissions@djad.in">Send an email to office&#64;djad.in</a></p>
      <p>DJ Academy of Design Coimbatore Pollachi Road Othakkalmandapam Coimbatore - 641 032 Tamil Nadu, India</p>
    </div>
  </div>
  <div class="modal-footer justify-center-login">
    <button type="button" class="btn btn-danger" (click)="modalRef.close()">Close</button>
  </div>
</div>
