import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service'
import { inject } from "@angular/core";

export const nonAuthGuard: CanActivateFn = (route, state) => {
  let authenticationService = inject(AuthenticationService);
  if(authenticationService.currentUserValue) {
    authenticationService.toHome();
    return false;
  } else {
    return true;
  }
  // let currentUser = null;
  // inject(AuthenticationService).currentUser.subscribe( data => {
  //   currentUser = data
  // });
  // if(currentUser) {
  //   inject(AuthenticationService).toHome();
  //   return false;
  // } else {
  //   return true;
  // }
};
