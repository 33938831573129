<div>
  <app-header-nav></app-header-nav>
  <div class="container py-4">
    <div class="row">
      <div class="col-md-12">
        <app-steps></app-steps>
        <div class="bg-color-default-theme text-white p-1 ps-2 border border-info">Please upload all the mandatory documents:</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="text-danger">1. Upload file size should be lesser than 2 MB</div>
          <div class="text-danger">2. Upload only PDF files</div>
          <div class="text-danger">3. After uploading your * Mandatory documents, click Next. If your Document type is uploaded successfully, the system will take you to the Make Payment tab.</div>
          <div class="text-danger">4. Document uploaded cannot be altered, so please upload only your final portfolios.</div>
          <br />
          <form (ngSubmit)="submit($event)" [formGroup]="form">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <label for="formFileLg" class="form-label">* Upload Documents Here:</label>
                  <mdb-form-control>
                    <select mdbInput formControlName="document_type" id="document_type" class="form-control form-control-lg">
                      <option value="" disabled hidden selected></option>
                      <option *ngFor="let type of types" [value]="type['id']">{{type['name']}} {{type['mandate'] ? '*' : ''}}</option>
                    </select>
                    <label mdbLabel class="form-label" for="document_type">Document Type:</label>
                  </mdb-form-control>
                  <div class="text-danger" *ngIf="(form.controls['document_type'].touched || submitted) && form.controls['document_type'].errors?.['required']">
                    Please select any one of the above option
                  </div>
                  <div class="mt-4">
                    <input class="form-control" (change)="documentChange($event)" formControlName="attachment" id="attachment" type="file" />
                  </div>
                  <div class="text-danger" *ngIf="(form.controls['attachment'].touched || submitted) && form.controls['attachment'].errors?.['required']">
                    Please upload file
                  </div>
                </div>
                <div class="col-md-3"></div>
            </div>
            <div class="text-center">
                <button type="submit" id="onlySave" class="btn btn-primary mt-4 me-3" [disabled]="isDocumentSubmitLoading">
                  <span
                    *ngIf="isDocumentSubmitLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span *ngIf="!isDocumentSubmitLoading">Save</span>
                </button>
                <button type="submit" id="saveAndcontinue" class="btn btn-primary mt-4 me-3" [disabled]="isDocumentSubmitLoading">
                  <span
                    *ngIf="isDocumentSubmitLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span *ngIf="!isDocumentSubmitLoading">Save and Continue</span>
                </button>
                <button (click)="redirectToPayment()" type="button" class="btn btn-primary mt-4" [disabled]="isDocumentSubmitLoading">
                  <span
                    *ngIf="isDocumentSubmitLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span *ngIf="!isDocumentSubmitLoading">Next</span>
                </button>
            </div>
          </form>
          <div *ngIf="documentLists && documentLists.length > 0" class="row mt-4">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document Type</th>
                      <th>Uploaded Date</th>
                      <th>Download</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let document of documentLists; index as i">
                      <td>{{i+1}}</td>
                      <td>{{document.document_type_name}}</td>
                      <td>{{document.createdAt}}</td>
                      <td><a href="{{document.document_download_link}}" target="_blank">View / Download</a></td>
                      <td class="text-center"><button class="btn btn-danger btn-sm" (click)="delete_document(document.documentid, document.document_type_name)"><i class="fa fa-trash" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
