import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrl: './payment-history.component.scss'
})
export class PaymentHistoryComponent implements OnInit {
  data: any;

  constructor(
    public modalRef: MdbModalRef<PaymentHistoryComponent>,
  ) {
  }

  ngOnInit() {
    this.data = this.modalRef.component.data;
  }
}
