import { Component } from '@angular/core';
import { RegistrationsService } from '../../utils/services/registrations.service';
import { MastersService } from '../../utils/services/masters.service';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../../utils/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preview-details',
  templateUrl: './preview-details.component.html',
  styleUrl: './preview-details.component.scss'
})
export class PreviewDetailsComponent {

  currentUser: any = null;
  currentUserSub: any = null;
  masters: any;
  personalData: any;
  headerText = "";
  fullName = "";
  salutation = "";
  gender = "";
  bloodgroup = "";
  guardian_relation = "";
  guardian_income= "";
  guardian_profession = "";
  state = ""
  permanent_state = "";
  education1_board = "";
  education2_board = "";
  nationality = "";
  country = "";
  permanent_country = "";
  application_status = "";
  enableSubmitBtn: boolean = false;
  isSubmitLoading: boolean = false;

  constructor(
    private registrationService: RegistrationsService,
    private mastersService: MastersService,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {

    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      if(data) {
        this.currentUser = data;
        if(this.currentUser?.is_personal_completed == 0) {
          this.router.navigate(['/personal-details']);
          this.messageService.add({ severity: 'error', detail: 'Please complete personal details section to preview details.', life: 3000 });
          this.application_status = "Personal Details Submission Pending";
        } else if(this.currentUser?.is_document_completed == 0) {
          this.application_status = "Document Submission Pending";
        } else if(this.currentUser?.is_payment_completed == 0) {
          this.application_status = "Payment Pending";
        } else if(this.currentUser?.is_submitted == 0) {
          this.application_status = "Not Submitted";
          this.enableSubmitBtn = true;
        } else if(this.currentUser?.is_submitted == 1) {
          this.application_status = "Submitted";
        } else {
          this.application_status = "Pending";
        }
      }
    });

    this.getMasters();
  }

  getMasters() {
    this.mastersService.read().subscribe((res: any) => {
      if(res.status) {
        this.masters = res.data;
        this.getPersonals();
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  getPersonals() {
    this.registrationService.get().subscribe((res: any) => {
      // console.log(res);
      if(res && res.status && res.statusCode == 200) {
        this.personalData = res.personal;
        this.salutation = this.mastersService.get_corresponding_name(this.masters.salutations, this.personalData?.salutation);
        this.fullName =  this.salutation + " " + this.personalData?.firstname + " " + this.personalData?.lastname;
        this.headerText = 'You are applying to ' + (this.personalData?.registration_type == '1' ? '4' : '2')  + ' Year ' + this.mastersService.get_corresponding_name((this.personalData?.registration_type == '1' ? this.masters.ug_disciplines : this.masters.pg_disciplines), this.personalData?.discipline);
        this.gender = this.mastersService.get_corresponding_name(this.masters.genders, this.personalData?.gender);
        this.bloodgroup = this.mastersService.get_corresponding_name(this.masters.blood_groups, this.personalData?.bloodgroup);
      
        this.guardian_relation = this.mastersService.get_corresponding_name(this.masters.relations, this.personalData?.guardian_relation);
        this.guardian_income = this.mastersService.get_corresponding_name(this.masters.annual_income, this.personalData?.guardian_income);
        this.guardian_profession = this.mastersService.get_corresponding_name(this.masters.professions, this.personalData?.guardian_profession);

        this.state = this.mastersService.get_corresponding_name(this.masters.states, this.personalData?.state);
        this.permanent_state = this.mastersService.get_corresponding_name(this.masters.states, this.personalData?.permanent_state);

        this.education1_board = this.mastersService.get_corresponding_name(this.masters.boards, this.personalData?.education1_board);
        this.education2_board = this.mastersService.get_corresponding_name(this.masters.boards, this.personalData?.education2_board);

        this.nationality = this.mastersService.get_country_name(this.masters.nations, this.personalData?.nationality);
        this.country = this.mastersService.get_country_name(this.masters.nations, this.personalData?.country);
        this.permanent_country = this.mastersService.get_country_name(this.masters.nations, this.personalData?.permanent_country);
      } else {
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }
  
  submitApn() {
    if(confirm("Are you sure you want to submit application? Once submitted, Personal Information & Documents cant be altered")) {
      this.isSubmitLoading = true;
      this.registrationService.submit_application().subscribe((res: any) => {
        this.isSubmitLoading = false;
        if(res) {
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
          if(res.status && res.statusCode == 200) {
            this.enableSubmitBtn = false;
            this.application_status = "Submitted";
            this.authenticationService.setCurrentUserValue(res.user_data);
            localStorage.setItem("currentUser", JSON.stringify(res.user_data));
          }
        } else {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        }
      }, (err: any) => {
        this.isSubmitLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        // console.log(err);
      });
    }
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}
