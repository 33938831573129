import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
const backEndUrl = environment.apiUrl + "api/masters/";

@Injectable({
  providedIn: 'root'
})
export class MastersService {

  constructor(private httpClient: HttpClient) { }

  read() {
    let url = backEndUrl + "get_masters";
    return this.httpClient.get(url);
  }

  get_corresponding_name(values: any, id: number, toCheck: string = "id", toReturn: string = "name") {
    for (const value of values) {
      if(value[toCheck] == id) {
        return value[toReturn];
      }
    }
  }

  get_correspondings(values: any, id: number, toCheck: string = "id") {
    for (const value of values) {
      if(value[toCheck] == id) {
        return value;
      }
    }
  }

  get_country_name(countries: any, id: number) {
    for (const country of countries) {
      if(country['country_id'] == id) {
        return country['short_name'];
      }
    }
  }
}
