import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { RegistrationsService } from '../../utils/services/registrations.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  form: FormGroup;
  submitted: boolean = false;
  isSubmitLoading: boolean = false;

  constructor(
    private fb: FormBuilder, 
    private messageService: MessageService,
    private registrationsService: RegistrationsService
  ) {
    this.form = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirm_password: ['', [Validators.required]]
    });
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      if(this.form.controls['new_password'].value != this.form.controls['confirm_password'].value) {
        this.messageService.add({ severity: 'error', detail: 'Password does not Match', life: 3000 });
        return;
      }
      this.isSubmitLoading = true;
      let data = this.form.value;
      this.registrationsService.change_password(data).subscribe((res: any) => {
        this.isSubmitLoading = false;
        if(res) {
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
          if(res.status && res.statusCode == 200) {
            this.form.reset();
            this.form.markAsUntouched();
            this.submitted = false;
          }
        } else {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        }
       }, (err: any) => {
        this.isSubmitLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        // console.log(err);
      });
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please Check all Mandatory Fields to Submit', life: 3000 });
    }
  }
}
