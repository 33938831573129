import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from './../../utils/services/authentication.service'

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.scss'
})
export class StepsComponent {

  currentUser: any = null;
  currentUserSub: any = null;
  items: MenuItem[] | undefined
  application_status: string = "";
  enablePreviewLink = false;

  constructor(private authenticationService: AuthenticationService) {

    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      if(data) {
        this.currentUser = data;
        if(this.currentUser?.is_personal_completed == 0) {
          this.application_status = "Personal Details Submission Pending";
        } else if(this.currentUser?.is_document_completed == 0) {
          this.application_status = "Document Submission Pending";
        } else if(this.currentUser?.is_payment_completed == 0) {
          this.application_status = "Payment Pending";
        } else if(this.currentUser?.is_submitted == 0) {
          this.application_status = "Not Submitted";
          this.enablePreviewLink = true;
        } else if(this.currentUser?.is_submitted == 1) {
          this.application_status = "Submitted";
        } else {
          this.application_status = "Pending";
        }
      }
    });

    this.items = [
      {
        label: 'Personal Info',
        routerLink: '/personal-details'
      },
      {
        label: 'Documents',
        routerLink: '/upload-document'
      },
      {
        label: 'Payment',
        routerLink: '/payment-section'
      }
    ];
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}
