import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
const backEndUrl = environment.apiUrl + "api/payments/";
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) { }

  get_payments() {
    let uniqueParam = Math.random() * 100;
    let url = backEndUrl + "get_payments?cacheBuster="+uniqueParam;
    return this.httpClient.get(url);
  }

  get_encryptdata() {
    let uniqueParam = Math.random() * 100;
    let url = backEndUrl + "encryptdata?cacheBuster="+uniqueParam;
    return this.httpClient.get(url);
  }

  get_invoices() {
    let uniqueParam = Math.random() * 100;
    let url = backEndUrl + "get_invoices?cacheBuster="+uniqueParam;
    return this.httpClient.get(url);
  }
  
  get_encryptinvoicedata(data: any) {
    let formData: FormData = new FormData();
    for(let key in data) {
      formData.append(key, data[key]);
    }

    let url = backEndUrl + "encryptinvoicedata";
    return this.httpClient.post(url, formData);
  }
}
