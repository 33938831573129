import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../utils/services/authentication.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PaymentService } from '../../utils/services/payment.service';
import { MastersService } from '../../utils/services/masters.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-fees-section',
  templateUrl: './fees-section.component.html',
  styleUrl: './fees-section.component.scss'
})
export class FeesSectionComponent {
  currentUser: any = null;
  currentUserSub: any = null;
  encryptedData: string = "";
  accessCode: string = environment.ccAvenueAccessKey;
  formAction: string = environment.ccAvenuePayUrl;
  apiUrl: string = environment.apiUrl;
  @ViewChild('form') form: ElementRef | undefined;
  invoiceLists: any = [];
  masters: any;
  statuses = [];
  subscription !: Subscription;
  isPaymentPending: boolean = false;

  paymentHistoryModalPgRef: MdbModalRef<PaymentHistoryComponent> | null = null;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private modalService: MdbModalService,
    private paymentService: PaymentService,
    private mastersService: MastersService
  ) {

    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      if(data) {
        this.currentUser = data;
      }
    });
    this.getMasters();
  }

  getMasters() {
    this.mastersService.read().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.masters = res.data;
        this.statuses = this.masters.payment_status;
        this.getInvoiceLists();
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  parseInvoices(invoices: any) {
    for(let i=0;i<invoices.length;i++) {
      if(invoices[i]['payment_status']) {
        let payment_status_info = this.mastersService.get_correspondings(this.statuses, invoices[i]['payment_status']);
        invoices[i]['payment_status_name'] = payment_status_info['name'];
        invoices[i]['payment_status_desc'] = payment_status_info['message'];
      }
      invoices[i]['isPaymentLoading'] = false;

    }
    return invoices;
  }

  parsePayments(payments: any) {
    for(let i=0;i<payments.length;i++) {
      let payment_status_info = this.mastersService.get_correspondings(this.statuses, payments[i]['payment_status']);
      payments[i]['payment_status_name'] = payment_status_info['name'];
      payments[i]['payment_status_desc'] = payment_status_info['message'];
    }
    return payments;
  }

  checkInvoiceStatus() {
    this.subscription = timer(0, 5000).pipe(
      switchMap(() => this.paymentService.get_invoices())
    ).subscribe((res: any) => {
      if(res.status) {
        this.invoiceLists = this.parseInvoices(res.invoices);
        let invoices = this.invoiceLists;
        let toRemoveInterval = true;
        for(let i=0;i<invoices.length;i++) {
          this.invoiceLists[i].paymentattempts = this.parsePayments(this.invoiceLists[i].paymentattempts);
          if(invoices[i]['payment_status'] == '1' || invoices[i]['payment_status'] == '2') {
            toRemoveInterval = false;
          }
        }
        if(toRemoveInterval) {
          this.isPaymentPending = false;
          this.subscription.unsubscribe();
        }
      }
    });
  }

  getInvoiceLists() {
    this.paymentService.get_invoices().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.invoiceLists = this.parseInvoices(res.invoices);
        let invoices = this.invoiceLists;
        let tosetInterval = false;
        if(invoices.length > 0) {
          for(let i=0;i<invoices.length;i++) {
            this.invoiceLists[i].paymentattempts = this.parsePayments(this.invoiceLists[i].paymentattempts);
            if(invoices[i]['payment_status'] == '1' || invoices[i]['payment_status'] == '2') {
              this.isPaymentPending = true;
              tosetInterval = true;
            }
          }
          if(tosetInterval) {
            this.checkInvoiceStatus();
          }
        }
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  initiatePayment(index: any) {
    this.invoiceLists[index].isPaymentLoading = true;
    let data = {
      invoiceid: this.invoiceLists[index].id
    }
    this.paymentService.get_encryptinvoicedata(data).subscribe((res: any) => {
      if(res) {
        if(res.status && res.statusCode == 200) {
          this.encryptedData = res.encrypted_data;
          setTimeout(() => {
            if(this.form) {
              this.invoiceLists[index].isPaymentLoading = false;
              this.form.nativeElement.submit();
            }
          }, 1000);
        } else {
          this.invoiceLists[index].isPaymentLoading = false;
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
        }
      } else {
        this.invoiceLists[index].isPaymentLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      }
    }, (err: any) => {
      this.invoiceLists[index].isPaymentLoading = false;
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  openHistoryModal(index: any) {
    let config = {
      modalClass: 'modal-lg',
      keyboard: false,
      ignoreBackdropClick: true,
      data: { data: this.invoiceLists[index].paymentattempts }
    }
    this.paymentHistoryModalPgRef = this.modalService.open(PaymentHistoryComponent, config);
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}
