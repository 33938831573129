import { Component, Input } from '@angular/core';
import { AuthenticationService } from './../../utils/services/authentication.service'
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrl: './header-nav.component.scss'
})
export class HeaderNavComponent {

  @Input() showHeaderNav: any = true;
  currentUser: any = null;
  currentUserSub: any = null;
  profilePicUrl = 'assets/profile_upload.png';

  constructor(private authenticationService: AuthenticationService, private router: Router) {
    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      this.currentUser = data;
      if(this.currentUser && this.currentUser.profile_photo) {
        this.profilePicUrl = environment.apiUrl + "uploads/registrations/" + this.currentUser.registrationid + "/" + this.currentUser.profile_photo;
      }
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  goToForm() {
    if(this.currentUser?.is_personal_completed != 1) {
      this.router.navigate(['/personal-details']);
    } else if(this.currentUser?.is_personal_completed == 1 && this.currentUser?.is_document_completed != 1) {
      this.router.navigate(['/upload-document']);
    } else if(this.currentUser?.is_personal_completed == 1 && this.currentUser?.is_document_completed == 1 &&this.currentUser?.is_payment_completed != 1) {
      this.router.navigate(['/payment-section']);
    } else {
      this.router.navigate(['/personal-details']);
    }
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }

}
