import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../utils/services/authentication.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PaymentService } from '../../utils/services/payment.service';
import { MastersService } from '../../utils/services/masters.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-payment-section',
  templateUrl: './payment-section.component.html',
  styleUrl: './payment-section.component.scss'
})
export class PaymentSectionComponent {

  currentUser: any = null;
  currentUserSub: any = null;
  encryptedData: string = "";
  accessCode: string = environment.ccAvenueAccessKey;
  formAction: string = environment.ccAvenuePayUrl;
  @ViewChild('form') form: ElementRef | undefined;
  paymentsLists: any = [];
  masters: any;
  statuses = [];
  subscription !: Subscription;
  isPaymentLoading: boolean = false;

  paymentDone: boolean = false;
  paymentPending: boolean = false;
  toShowPayBtn: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private paymentService: PaymentService,
    private mastersService: MastersService
  ) {

    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      if(data) {
        this.currentUser = data;
        if(this.currentUser?.is_personal_completed != 1) {
          this.router.navigate(['/personal-details']);
          this.messageService.add({ severity: 'error', detail: 'Please complete personal details & document upload section to continue further.', life: 3000 });
        } else if(this.currentUser?.is_document_completed != 1) {
          this.router.navigate(['/upload-document']);
          this.messageService.add({ severity: 'error', detail: 'Please complete document upload section to continue further.', life: 3000 });
        }
      }
    });
    this.getMasters();
  }

  getMasters() {
    this.mastersService.read().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.masters = res.data;
        this.statuses = this.masters.payment_status;
        this.getPaymentsLists();
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  parsePayments(payments: any) {
    for(let i=0;i<payments.length;i++) {
      let payment_status_info = this.mastersService.get_correspondings(this.statuses, payments[i]['payment_status']);
      payments[i]['payment_status_name'] = payment_status_info['name'];
      payments[i]['payment_status_desc'] = payment_status_info['message'];
    }
    return payments;
  }

  checkPaymentsStatus() {
    this.subscription = timer(0, 5000).pipe(
      switchMap(() => this.paymentService.get_payments())
    ).subscribe((res: any) => {
      if(res.status) {
        this.paymentsLists = this.parsePayments(res.payments);
        let payments = this.paymentsLists;
        let flag = false;
        for(let i=0;i<payments.length;i++) {
          if(payments[i]['payment_status'] == '1' || payments[i]['payment_status'] == '2') {
            flag = true;
            this.toShowPayBtn = false;
            this.paymentDone = false;
            this.paymentPending = true;
            break;
          } else if(payments[i]['payment_status'] == '6') {
            this.currentUser.is_payment_completed = 1;
            this.authenticationService.setCurrentUserValue(this.currentUser);
            localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
            this.toShowPayBtn = false;
            this.paymentPending = false;
            this.paymentDone = true;
            break;
          }
        }
        if(flag == false) {
          this.subscription.unsubscribe();
        }
      }
    });
  }

  getPaymentsLists() {
    this.paymentService.get_payments().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.paymentsLists = this.parsePayments(res.payments);
        let payments = this.paymentsLists;
        let flag = true;
        let tosetInterval = false;
        if(payments.length > 0) {
          for(let i=0;i<payments.length;i++) {
            if(payments[i]['payment_status'] == '1' || payments[i]['payment_status'] == '2' || payments[i]['payment_status'] == '6') {
              flag = false;
              if(payments[i]['payment_status'] == '1' || payments[i]['payment_status'] == '2') {
                tosetInterval = true;
                this.paymentDone = false;
                this.paymentPending = true;
              } else if(payments[i]['payment_status'] == '6') {
                this.currentUser.is_payment_completed = 1;
                this.authenticationService.setCurrentUserValue(this.currentUser);
                localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
                this.paymentPending = false;
                this.paymentDone = true;
              }
              break;
            }
          }
          if(tosetInterval) {
            this.checkPaymentsStatus();
          }
          this.toShowPayBtn = flag;
        } else {
          this.toShowPayBtn = true;
        }
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  initiatePayment() {
    this.isPaymentLoading = true;
    this.paymentService.get_encryptdata().subscribe((res: any) => {
      if(res) {
        if(res.status && res.statusCode == 200) {
          this.encryptedData = res.encrypted_data;
          setTimeout(() => {
            if(this.form) {
              this.isPaymentLoading = false;
              this.form.nativeElement.submit();
            }
          }, 1000);
        } else {
          this.isPaymentLoading = false;
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
        }
      } else {
        this.isPaymentLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      }
    }, (err: any) => {
      this.isPaymentLoading = false;
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}
