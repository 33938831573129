<div>
  <app-header-nav></app-header-nav>
  <div class="container py-4">
    <div class="row">
      <div class="col-md-12">
        <app-steps></app-steps>
        <div class="bg-color-default-theme text-white p-1 ps-2 border border-info">Credit/Debit Cards/Internet Banking:</div>
        <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
          <div class="text-end mt-4 mb-3">
            <button class="d-none btn btn-primary me-3">Print Preview</button>
            <button class="d-none btn btn-primary me-3">View Payment Instructions</button>
          </div>
          <div class="text-center">
            <h2>DJAD Admissions {{currentUser.registration_type == 1 ? "UG" : "PG"}}</h2>
            <p>Payment through Credit/Debit Cards/Internet Banking</p>
            <p>(CCAvenue Payment Gateway)</p>
          </div>
          <div class="table-responsive mt-3" *ngIf="paymentsLists && paymentsLists.length > 0">
            <table class="table table-sm table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Amount (Rs)</th>
                  <th>Payment Mode</th>
                  <th>Transaction ID</th>
                  <th>Payment Status</th>
                  <th>Payment Date</th>
                </tr>
              </thead>
              <tbody>
                <!-- Add table rows here -->
                <tr *ngFor="let payment of paymentsLists; index as i">
                  <td>{{ i+1 }}</td>
                  <td>{{payment.amount}}</td>
                  <td>{{payment.payment_mode_name}}</td>
                  <td>{{payment.transaction_id}}</td>
                  <td>{{payment.payment_status_name}}</td>
                  <td>{{payment.payment_date}}</td>
                </tr>
                <!-- Add more rows as needed -->
              </tbody>
            </table>
          </div>
          
          <div class="text-center mt-4" *ngIf="toShowPayBtn">
            <h5>TOTAL AMOUNT TO PAY: INR 1500</h5>
            <button class="btn btn-success" (click)="initiatePayment()" [disabled]="isPaymentLoading">
              <span
                  *ngIf="isPaymentLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
              ></span>
              <span *ngIf="!isPaymentLoading">Click here to Pay</span>
            </button>
          </div>

          <div class="text-center" *ngIf="paymentDone">
            <h5 class="text-danger mt-4">Upon successfull payment, please click on the preview button, kindly scroll down to botton of the page and click Submit button</h5>
            <a routerLink="/preview-details" class="btn btn-success">Application Preview</a>
          </div>

          <div class="text-center" *ngIf="paymentPending">
            <h5 class="text-danger mt-4">We are fetching your payment status, please wait while we complete your payment status checking process...</h5>
          </div>

          <form #form ngNoForm
            id="nonseamless" 
            method="post" 
            name="redirect" 
            action="{{formAction}}">
              <input type="hidden" id="encRequest" name="encRequest" value="{{encryptedData}}">
              <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
          </form>

          <div class="row d-none">
            <div class="col-3">
              <div class="bg-danger p-1">
                <h6 class="text-light text-center mt-3">TOTAL AMOUNT TO PAY</h6>
                <p class="fw-bold fs-4 text-light text-center">&#8377; <span class="">1500</span></p>
              </div>
            </div>
            <div class="col-8 d-flex align-items-center justify-content-center">
              <div>
                <a href="#" class="btn btn-success">Click here to Pay</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
