import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../../../utils/services/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})

export class ForgotPasswordComponent {

  form: FormGroup;
  submitted: boolean = false;
  isSubmitLoading: boolean = false;

  constructor(
    public modalRef: MdbModalRef<ForgotPasswordComponent>,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
  }

  submitEmail() {
    this.submitted = true;
    if(this.form.valid) {
      this.isSubmitLoading = true;
      let key = {
        email: this.form.controls['email'].value,
      }
      this.authenticationService.forgot_password(key).subscribe((res: any) => {
        this.isSubmitLoading = false;
        if(res) {
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
          if(res.status && res.statusCode == 200) {
            setTimeout(() => {
              this.modalRef.close();
            }, 1000);
          }
        } else {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        }
       }, (err: any) => {
        this.isSubmitLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        // console.log(err);
      });
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please Check all Mandatory Fields to Submit', life: 3000 });
    }
  }
}
