<div class="modal-content">
    <div class="modal-header bg-info text-white">
        <h5 class="modal-title">Payment History</h5>
        <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
    </div>
    <div class="modal-body">
        <div *ngIf="data" class="">
            <div class="table-responsive">
                <table class="table table-bordered table-hover table-sm table-striped mb-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Amount (Rs)</th>
                            <th>Payment Mode</th>
                            <th>Transaction ID</th>
                            <th>Payment Status</th>
                            <th>Payment Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Add table rows here -->
                        <tr *ngFor="let payment of data; index as i">
                            <td>{{ i+1 }}</td>   
                            <td>{{payment.amount}}</td>
                            <td>{{payment.payment_mode_name}}</td>
                            <td>{{payment.transaction_id}}</td>
                            <td>{{payment.payment_status_name}}</td>
                            <td>{{payment.payment_date}}</td>
                        </tr>
                        <!-- Add more rows as needed -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>