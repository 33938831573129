<div class="modal-content">
  <div class="modal-header bg-info text-white">
    <h5 class="modal-title">DJAD 2024 ADMISSION CALENDER</h5>
    <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="">
      <table class="table table-bordered table-hover table-sm table-striped border-dark table-sm">
        <thead>
          <tr>
            <th><strong>PARTICULARS</strong></th>
            <th><strong>DATES</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Admission announcement</td>
            <td>14 December 2023</td>
          </tr>
          <tr>
            <td>DEED 2024 (DJAD Entrance Exam on Design)</td>
            <td>Phase 2 Will be 6 and 7 April 2024</td>
          </tr>
          <tr>
            <td>Dates will be informed to the candidates individually.</td>
            <td>2024-03-01</td>
          </tr>
          <tr>
            <td>Merit List of Provisionally selected candidates</td>
            <td>Will be announced within 5 days after the Interview.</td>
          </tr>

          <!-- Add more rows as needed -->
        </tbody>
      </table>
      <p class="text-danger">To participate in Admission process, Please register immediately and pay the application fee online.</p>
    </div>
  </div>
  <div class="modal-footer justify-center-login">
    <button type="button" class="btn btn-danger" (click)="modalRef.close()">Close</button>
  </div>
</div>
