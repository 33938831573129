import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrl: './help-desk.component.scss'
})
export class HelpDeskComponent {
  constructor(public modalRef: MdbModalRef<HelpDeskComponent>) {}
}
