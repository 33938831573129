<div class="modal-content modal-xxl">
    <div class="modal-header bg-info text-light">
      <h5 class="modal-title">Terms and Conditions</h5>
      <button type="button" class="btn-close bg-light" aria-label="Close" (click)="modalRef.close()"></button>
    </div>
    <div class="modal-body">
      <div class="">
        <h6 class="text-danger mb-4">IMPORTANT: Before initiating the online registration, please ensure that you go to your browser settings and TURN OFF the auto fill option to avoid incorrect information being entered.</h6>
        <p>
          <strong><u>General Information</u></strong>
        </p>
        <p>DJAD M.Des (Post Graduate Programme) is collaborated with Alagappa University, Tamil Nadu.</p>
        <p><strong>Programmes offered are</strong></p>
        <ol>
          <li>M.Des Industrial Design (Includes the design domains viz. Product Design, Transportation Design, Furniture & Space Design, Jewellery Design, Ceramic & Glass Design, Toy & Game Design, Universal Design etc.)</li>
          <li>M.Des Communication Design (Includes the design domains viz. Graphic Design, Animation and Video Film Design, Photography Design, UI/UX, Digital Game Design, Immersive Media Design etc.)</li>
          <li>M.Des User Experience Design (Deals with interface between human beings and machines.)</li>
        </ol>
        <p>Admission is offered to candidates, based on their performance in the Aptitude Test, Portfolio as well as performance in the personal interview conducted for assessing their aptitude in Design.</p>
        <p>
          <strong><u>Eligibility Criteria</u></strong>
        </p>
        <p>Eligibility</p>
        <ol>
          <li>
            The candidate must possess at least one of the following minimum qualifications to be considered eligible for admissions:
          </li>
          <li>Undergraduate program of minimum 3 year duration in any specialization with minimum 50% aggregate marks, after 10+2 system, from any university or institute recognised by law in India. Condition: Applicant must have passed / have appeared for / be appearing for the Bachelor degree examinations before July of the Year of admission OR</li>
          <li>Full-time Diploma of minimum 4-year duration in Design / Fine Arts / Applied Arts / Architecture with minimum 50% aggregate marks, after 10+2 system, from any university or institute recognised by law in India. Condition: Applicants must have passed / have appeared for / be appearing for the Diploma examinations before July of the Year of admission</li>
         
        </ol>
        <p>
          <u><strong>Age</strong></u>
        </p>
        <ol>
          <li>Age	20 years and above</li>
        </ol>
        <p>
          <strong><u>Registration Instructions</u></strong>
        </p>
        <ol>
          <li>Please ensure availability of the soft copy of your 10th/SSC and 12th/HSC marksheets and passport size photo in jpg/jpeg/png format. (File size should not exceed 150kb each.)</li>
          <li>Please choose "Appearing" in case you are appearing for the 12th standard exam and/or awaiting results this year.</li>
          <li>Click on "Register"</li>
          <li>Read the Instructions carefully and then click on the 'I Agree' button to accept the Terms and Conditions.</li>
          <li>Fill in the details requested on the page displayed.</li>
          <li>Click on "Save and Continue" to choose a password. Then click on "Submit" to register.</li>
          <li>After Registration, you will get a registration email containing your DJAD ID and password.</li>
          <li>Fill the remaining application form.</li>
          <li>Now the Application will redirect to Payment page.</li>
        </ol>
        <p>
          <strong><u>Payment Information</u></strong>
        </p>
        <ol>
          <li>Pay a fee of Rs.1,500/-</li>
          <li>You have the following option for payment mode.</li>
          <li>ONLINE PAYMENT THROUGH CCAVENUE PAYMENT GATEWAY</li>
        </ol>
        <!-- <p>
          <strong><u>Admit Card Instructions</u></strong>
        </p>
        <div class="mb-3 form-check">
          <input type="checkbox" class="form-check-input" id="terms" />
          <label class="form-check-label" for="terms">If you agree to the above terms and conditions, click "I agree" and please complete your form by filling all the parts of your application.</label>
        </div>
        <button type="submit" class="btn btn-success agree">I Agree</button>
        <button type="submit" class="btn btn-danger">I Disagree</button> -->
      </div>
    </div>
    <div class="modal-footer justify-center-login">
      <button type="button" class="btn btn-danger" (click)="modalRef.close()">Close</button>
    </div>
</div>  