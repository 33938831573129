<div>
    <app-header-nav [showHeaderNav]="false"></app-header-nav>
    <div class="container py-4">
        <div class="row">
            <form (ngSubmit)="register()" [formGroup]="form">
                <div class="col-md-12">
                    <div class="bg-color-default-theme text-white p-1 ps-2">Discipline offered</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <span class="small font-color-default-theme">Note: Choice of discipline versus your aptitude could be discussed and your choice of discipline could be altered during the interview process if you feel so.</span>
                        <div class="row pt-2">
                            <div class="col-md-2"></div>
                            <div class="col-md-7">
                                <div>
                                    * Select Program Preference
                                    <div class="ps-5 py-2 mb-1">
                                        <div *ngFor="let course_group of course_groups" class="form-check form-check-inline">
                                            <input mdbRadio class="form-check-input" type="radio" formControlName="registration_type" id="registration_type-{{course_group['group_id']}}" [value]="course_group['group_id']" />
                                            <label class="form-check-label" for="registration_type-{{course_group['group_id']}}">{{course_group['group_name']}}</label>
                                        </div>
                                    </div>
                                    <div class="text-danger" *ngIf="(form.controls['registration_type'].touched || submitted) && form.controls['registration_type'].errors?.['required']">
                                        Please select any one of the above option
                                    </div>
                                </div>
                                <div *ngIf="form.controls['registration_type'].value">
                                    * Choose one Discipline
                                    <div class="ps-5 pt-2 mb-1">
                                        <div *ngFor="let discipline of disciplines" class="form-check">
                                            <input mdbRadio class="form-check-input" type="radio" formControlName="discipline" [value]="discipline['id']" id="discipline-{{discipline['id']}}" />
                                            <label class="form-check-label" for="discipline-{{discipline['id']}}">{{discipline['description']}}</label>
                                        </div>
                                    </div>
                                    <div class="text-danger" *ngIf="(form.controls['discipline'].touched || submitted) && form.controls['discipline'].errors?.['required']">
                                        Please select any one of the above option
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                    <div class="bg-color-default-theme text-white p-1 ps-2">Registration Details</div>
                    <div class="p-3 py-3 p-lg-5 py-lg-3 border border-info">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-5">
                                <mdb-form-control class="mb-1">
                                    <select mdbInput formControlName="salutation" id="salutation" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let salutation of salutations" [value]="salutation['id']">{{salutation['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="salutation">* Salutation</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['salutation'].touched || submitted) && form.controls['salutation'].errors?.['required']">
                                    Please select any one of the above option
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="text" formControlName="firstname" id="firstname" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="firstname">* First Name</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['firstname'].touched || submitted) && form.controls['firstname'].errors?.['required']">
                                    First Name is required
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="text" formControlName="middlename" id="middlename" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="middlename">Middle Name</label>
                                </mdb-form-control>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="text" formControlName="lastname" id="lastname" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="lastname">* Last Name</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['lastname'].touched || submitted) && form.controls['lastname'].errors?.['required']">
                                    Last Name is required
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="date" formControlName="dob" id="dob" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="dob">* Date Of Birth</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['dob'].touched || submitted) && form.controls['dob'].errors?.['required']">
                                    DOB is required
                                </div>
                                <div class="mt-3 mb-1">
                                    <span class="me-4">* Gender</span>
                                    <div class="ps-5 pt-2">
                                        <div *ngFor="let gender of genders" class="form-check form-check-inline">
                                            <input mdbRadio class="form-check-input" type="radio" formControlName="gender" id="gender-{{gender['id']}}" [value]="gender['id']" />
                                            <label class="form-check-label" for="gender">{{gender['name']}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['gender'].touched || submitted) && form.controls['gender'].errors?.['required']">
                                    Please select any one of the above option
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <select mdbInput formControlName="category" id="category" class="form-control form-control-lg">
                                        <option value="" disabled hidden selected></option>
                                        <option *ngFor="let category of categories" [value]="category['id']">{{category['name']}}</option>
                                    </select>
                                    <label mdbLabel class="form-label" for="category">* Category</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['category'].touched || submitted) && form.controls['category'].errors?.['required']">
                                    Please select any one of the above option
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="text" formControlName="mobile" id="mobile" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="mobile">* Mobile Number</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['mobile'].touched || submitted) && form.controls['mobile'].errors?.['required']">
                                    Mobile Number is required
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['mobile'].touched || submitted) && (form.controls['mobile'].errors?.['minlength'] || form.controls['mobile'].errors?.['maxlength'])">
                                    Please enter valid 10 Digit Mobile Number
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['mobile'].touched || submitted) && form.controls['mobile'].errors?.['pattern']">
                                    Please enter valid Mobile Number
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="email" email="true" formControlName="email" id="email" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="email">* Primary Email</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['email'].touched || submitted) && form.controls['email'].errors?.['required']">
                                    Email is required
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['email'].touched || submitted) && (form.controls['email'].errors?.['email'] || form.controls['email'].errors?.['pattern'])">
                                    Please provide valid Email
                                </div>
                                <mdb-form-control class="mt-3 mb-1">
                                    <input mdbInput type="email" email="true" formControlName="confirm_email" id="confirm_email" class="form-control form-control-lg" />
                                    <label mdbLabel class="form-label" for="confirm_email">* Retype Primary Email</label>
                                </mdb-form-control>
                                <div class="text-danger" *ngIf="(form.controls['confirm_email'].touched || submitted) && form.controls['confirm_email'].errors?.['required']">
                                    Please Re-enter Email to Confirm
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['confirm_email'].touched || submitted) && (form.controls['confirm_email'].errors?.['email'] || form.controls['confirm_email'].errors?.['pattern'])">
                                    Please provide valid Email
                                </div>
                                <div class="text-danger" *ngIf="(form.controls['confirm_email'].touched || submitted) && (form.controls['confirm_email'].value != form.controls['email'].value)">
                                    Email does not Match
                                </div>
                                <div class="mt-3 form-check" *ngIf="form.controls['registration_type'].value">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="terms_and_conditions"
                                        formControlName="terms_and_conditions"
                                    />
                                    <label class="form-check-label" for="terms_and_conditions">
                                        By clicking this checkbox, you agree to our 
                                        <a href="javascript:;" *ngIf="form.controls['registration_type'].value == 1" (click)="terms_condition()" class="text-primary">Terms and Conditions (UG)</a>
                                        <a href="javascript:;" *ngIf="form.controls['registration_type'].value == 2" (click)="terms_condition_pg()" class="text-primary">Terms and Conditions (PG)</a>.
                                    </label>
                                    <div class="text-danger" *ngIf="(form.controls['terms_and_conditions'].touched || submitted) && form.controls['terms_and_conditions'].errors?.['required']">
                                        Please click the checkbox to proceed with registration.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5"></div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary mt-4">Register</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>