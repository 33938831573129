import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-terms-condition-pg',
  templateUrl: './terms-condition-pg.component.html',
  styleUrl: './terms-condition-pg.component.scss'
})
export class TermsConditionPgComponent {
  constructor(public modalRef: MdbModalRef<TermsConditionPgComponent>) {}
}
