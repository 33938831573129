import { Component, Inject, Input, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AuthenticationService } from '../../../utils/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrl: './register-confirm.component.scss'
})
export class RegisterConfirmComponent implements OnInit {

  data: any;
  otpTriggerd: boolean = false;
  otpSubmitted: boolean = false;
  invalidOtp: boolean = false;
  wrongOtp: boolean = false;
  expiredOtp: boolean = false;
  otpVerified: boolean = false;
  registrationId = "";
  form: FormGroup;

  isOtpTriggerLoading: boolean = false;
  isResendOtpTriggerLoading: boolean = false;
  isOtpSubmitLoading: boolean = false;

  constructor(
    public modalRef: MdbModalRef<RegisterConfirmComponent>,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder, 
    private router: Router,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({
      otpValue: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    });
  }

  ngOnInit() {
    this.data = this.modalRef.component.data;
  }

  submit() {
    this.isOtpTriggerLoading = true;
    let key = {
      'registration_type': this.data.registration_type,
      'discipline': this.data.discipline,
      'salutation': this.data.salutation,
      'firstname': this.data.firstname,
      'middlename': this.data.middlename,
      'lastname': this.data.lastname,
      'dob': this.data.dob,
      'gender': this.data.gender,
      'category': this.data.category,
      'mobile': this.data.mobile,
      'email': this.data.email,
      'confirm_email': this.data.confirm_email
    };
    this.authenticationService.register(key).subscribe((res: any) => {
      this.isOtpTriggerLoading = false;
      if(res) {
        this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
        if(res.statusCode == 200) {
          this.registrationId = res.registrationid;
          this.otpTriggerd = true;
        }
      }
     }, (err: any) => {
      this.isOtpTriggerLoading = false;
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  submitOtp() {
    this.otpSubmitted = true;
    if(this.form.valid) {
      this.isOtpSubmitLoading = true;
      let key = {
        'registrationid': this.registrationId,
        'otp': this.form.controls['otpValue'].value
      }
      // console.log(key);
      this.authenticationService.verify_otp(key).subscribe((res: any) => {
        this.isOtpSubmitLoading = false;
        if(res) {
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
          if(res.status && res.statusCode == 200) {
            this.modalRef.close();
            this.otpVerified = true;
            this.router.navigate(['/']);
          }
        } else {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        }
       }, (err: any) => {
        this.isOtpSubmitLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        // console.log(err);
      });
    }
  }

  resendOtp() {
    if(this.registrationId) {
      this.isResendOtpTriggerLoading = true;
      let key = {
        'registrationid': this.registrationId
      }
      this.authenticationService.resend_otp(key).subscribe((res: any) => {
        this.isResendOtpTriggerLoading = false;
        if(res) {
          this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
        } else {
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        }
       }, (err: any) => {
        this.isResendOtpTriggerLoading = false;
        this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
        // console.log(err);
      });
    } else {
      this.messageService.add({ severity: 'error', detail: 'No Registration details found', life: 3000 });
    }
  }
}