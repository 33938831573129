import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MastersService } from '../../utils/services/masters.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';
import { TermsConditionComponent } from './../login/terms-condition/terms-condition.component';
import { TermsConditionPgComponent } from './../login/terms-condition-pg/terms-condition-pg.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {

  form: FormGroup;
  masters: any;
  submitted = false;
  disciplines = [];
  categories = [];
  salutations = [];
  genders = [];
  course_groups = [];
  selectedGroup = undefined;

  registerConfirmModalPgRef: MdbModalRef<RegisterConfirmComponent> | null = null;
  termsModalRef: MdbModalRef<TermsConditionComponent> | null = null;
  termsModalPgRef: MdbModalRef<TermsConditionPgComponent> | null = null;

  constructor(
    private fb: FormBuilder, 
    private mastersService: MastersService, 
    private modalService: MdbModalService,
    private messageService: MessageService
  ) { 
    this.getMasters();

    this.form = this.fb.group({
      registration_type: ['', Validators.required],
      discipline: ['', Validators.required],
      salutation: ['', Validators.required],
      firstname: ['', Validators.required],
      middlename: [''],
      lastname: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      category: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      confirm_email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      terms_and_conditions: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.form.controls['registration_type'].valueChanges.pipe().subscribe(() => {
      this.form.controls['discipline'].setValue("");
      this.disciplines = [];
      this.course_groups.forEach(element => {
        if(this.form.controls['registration_type'].value == element['group_id']) {
          this.disciplines = element['items'];
          this.selectedGroup = element;
        }
      });
    });
  }

  getMasters() {
    this.mastersService.read().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.masters = res.data;
        this.course_groups = this.masters.course_groups;
        this.salutations = this.masters.salutations;
        this.genders = this.masters.genders;
        this.categories = this.masters.categories;
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  terms_condition() {
    this.termsModalRef = this.modalService.open(TermsConditionComponent, { modalClass: 'modal-xl' })
  }

  terms_condition_pg() {
    this.termsModalPgRef = this.modalService.open(TermsConditionPgComponent, { modalClass: 'modal-xl' })
  }

  register() {
    this.submitted = true;
    if (this.form.valid) {
      if(this.form.controls['confirm_email'].value != this.form.controls['email'].value) {
        this.messageService.add({ severity: 'error', detail: 'Email does not Match', life: 3000 });
        return;
      }
      if(this.form.controls['terms_and_conditions'].value == false) {
        this.messageService.add({ severity: 'error', detail: 'Please accept Terms & Conditions to proceed with registration.', life: 3000 });
        return;
      }
      let data = this.form.value;
      delete data['terms_and_conditions'];
      data['registration_type_name'] = this.selectedGroup ? this.selectedGroup['group_name'] : '';
      data['discipline_name'] = this.selectedGroup ? this.mastersService.get_corresponding_name(this.selectedGroup['items'], data.discipline, 'id', 'description') : '';
      data['salutation_name'] = this.mastersService.get_corresponding_name(this.salutations, data.salutation);
      data['gender_name'] = this.mastersService.get_corresponding_name(this.genders, data.gender);
      data['category_name'] =this.mastersService.get_corresponding_name(this.categories, data.category);
      let config = {
        modalClass: 'modal-lg',
        keyboard: false,
        ignoreBackdropClick: true,
        data: { data }
      }
      this.registerConfirmModalPgRef = this.modalService.open(RegisterConfirmComponent, config);
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please Check all Mandatory Fields to Submit', life: 3000 });
    }
  }
}
