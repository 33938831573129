import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrl: './registration-details.component.scss'
})
export class RegistrationDetailsComponent {
  constructor(public modalRef: MdbModalRef<RegistrationDetailsComponent>) {}
}
