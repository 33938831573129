import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../utils/services/authentication.service'

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrl: './index.component.scss'
})

export class IndexComponent {
  currentUser: any = null;
  currentUserSub: any = null;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      this.currentUser = data;
      if(this.currentUser) {
        if (this.currentUser?.is_converted == 1) {
          this.router.navigate(['/invoice-section']);
        } else if(this.currentUser?.is_personal_completed != 1) {
          this.router.navigate(['/personal-details']);
        } else if(this.currentUser?.is_personal_completed == 1 && this.currentUser?.is_document_completed != 1) {
          this.router.navigate(['/upload-document']);
        } else if(this.currentUser?.is_personal_completed == 1 && this.currentUser?.is_document_completed == 1 && this.currentUser?.is_payment_completed != 1) {
          this.router.navigate(['/payment-section']);
        } else if(this.currentUser?.is_personal_completed == 1 && this.currentUser?.is_document_completed == 1 && this.currentUser?.is_payment_completed == 1) {
          this.router.navigate(['/preview-details']);
        } else {
          this.router.navigate(['/personal-details']);
        }
      }
    });
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}