import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationsService } from '../../utils/services/registrations.service';
import { MastersService } from '../../utils/services/masters.service';
import { AuthenticationService } from '../../utils/services/authentication.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrl: './personal-details.component.scss'
})
export class PersonalDetailsComponent {
  form: FormGroup;
  personalData: any;
  masters: any;
  submitted = false;
  disciplines = [];
  categories = [];
  salutations = [];
  genders = [];
  nations = [];
  annualincomes = [];
  relations = [];
  professions = [];
  states = [];
  boards = [];
  blood_groups = [];
  years: any = [];
  corres_states: any = [];
  permanent_states: any = [];

  course_groups = [];
  selectedGroup = undefined;

  headerText = '';

  profilePicObject = null;
  profilePicPreview = 'assets/profile_upload.png';
  SignPicObject = null;
  SignPicPreview = 'assets/sign_upload.png';

  currentUser: any = null;
  currentUserSub: any = null;

  isPersonalLoading = false;

  constructor(
    private fb: FormBuilder, 
    private registrationService: RegistrationsService,
    private authenticationService: AuthenticationService,
    private mastersService: MastersService,
    private messageService: MessageService,
    private router: Router,
  ) {
    this.getMasters();

    this.currentUserSub = this.authenticationService.currentUser.subscribe((data) => {
      if(data) {
        this.currentUser = data;
      }
    });

    this.form = this.fb.group({
      registration_type: [{value: '', disabled: true}, Validators.required],
      discipline: [{value: '', disabled: true}, Validators.required],
      salutation: [{value: '', disabled: true}, Validators.required],
      firstname: [{value: '', disabled: true}, Validators.required],
      middlename: [{value: '', disabled: true}],
      lastname: [{value: '', disabled: true}, Validators.required],
      dob: [{value: '', disabled: true}, Validators.required],
      gender: [{value: '', disabled: true}, Validators.required],
      category: [{value: '', disabled: true}, Validators.required],
      mobile: [{value: '', disabled: true}, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
      confirm_email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
      birth_place: [{value: ''}, Validators.required],
      nationality: [{value: ''}, Validators.required],
      height: [{value: ''}],
      weight: [{value: ''}],
      bloodgroup: [{value: ''}],
      guardian_name: [{value: ''}, Validators.required],
      guardian_relation: [{value: ''}, Validators.required],
      guardian_income: [{value: ''}, Validators.required],
      guardian_profession: [{value: ''}],
      guardian_designation: [{value: ''}, Validators.required],
      address_line1: [{value: ''}, Validators.required],
      address_line2: [{value: ''}],
      address_line3: [{value: ''}],
      city: [{value: ''}, Validators.required],
      state: [{value: ''}, Validators.required],
      country: [{value: ''}, Validators.required],
      pincode: [{value: ''}, Validators.required],
      phonenumber: [{value: ''}, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      address_same: [{value: '1'}, Validators.required],
      permanent_address_line1: [{value: ''}],
      permanent_address_line2: [{value: ''}],
      permanent_address_line3: [{value: ''}],
      permanent_city: [{value: ''}],
      permanent_state: [{value: ''}],
      permanent_country: [{value: ''}],
      permanent_pincode: [{value: ''}],
      education1_school: [{value: ''}, Validators.required],
      education1_board: [{value: ''}, Validators.required],
      education1_place: [{value: ''}, Validators.required],
      education1_subject: [{value: ''}, Validators.required],
      education1_yop: [{value: ''}, Validators.required],
      education1_percent: [{value: ''}, [Validators.required, Validators.min(1), Validators.max(100)]],
      education2_school: [{value: ''}, Validators.required],
      education2_stream: [{value: ''}, Validators.required],
      education2_board: [{value: ''}, Validators.required],
      education2_place: [{value: ''}, Validators.required],
      education2_subject: [{value: ''}, Validators.required],
      education2_status: [{value: ''}, Validators.required],
      education2_exam: [{value: ''}],
      education2_explain: [{value: ''}],
      website_lead: [{value: ''}],
      newspaper_lead: [{value: ''}],
      wom_lead: [{value: ''}],
      coaching_lead: [{value: ''}],
      other_lead: [{value: ''}],
      sign_photo: [],
      profile_photo: []
    });
  }

  profilePicChange(event: any) {
    let fileSrc = event.target.files[0];
    if(fileSrc.type == 'image/png' || fileSrc.type == 'image/gif' || fileSrc.type == 'image/jpeg' || fileSrc.type == 'image/jpg') {
      if(fileSrc.size <= 153600) {
        this.profilePicObject = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          this.profilePicPreview = reader.result as string;
        }
        reader.readAsDataURL(event.target.files[0]);
      } else {
        this.messageService.add({ severity: 'error', detail: 'Please upload file less than 150KB', life: 3000 });
        this.form.controls['profile_photo'].setValue("");
        this.form.controls['profile_photo'].markAsUntouched();
      }
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please upload only .png, .jpeg, /jpg, .gif file.', life: 3000 });
      this.form.controls['profile_photo'].setValue("");
      this.form.controls['profile_photo'].markAsUntouched();
    }
  }

  signPicChange(event: any) {
    let fileSrc = event.target.files[0];
    if(fileSrc.type == 'image/png' || fileSrc.type == 'image/gif' || fileSrc.type == 'image/jpeg' || fileSrc.type == 'image/jpg') {
      if(fileSrc.size <= 153600) {
        this.SignPicObject = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          this.SignPicPreview = reader.result as string;
        }
        reader.readAsDataURL(event.target.files[0]);
      } else {
        this.messageService.add({ severity: 'error', detail: 'Please upload file less than 150KB', life: 3000 });
        this.form.controls['sign_photo'].setValue("");
        this.form.controls['sign_photo'].markAsUntouched();
      }
    } else {
      this.messageService.add({ severity: 'error', detail: 'Please upload only .png, .jpeg, /jpg, .gif file.', life: 3000 });
      this.form.controls['sign_photo'].setValue("");
      this.form.controls['sign_photo'].markAsUntouched();
    }
  }

  ngOnInit() {

    var date = new Date();
    var year = date.getFullYear() - 15;
    for(var i=0;i<=15;i++) {
      this.years.push(year + i);
    }

    this.form.controls['registration_type'].valueChanges.pipe().subscribe(() => {
      this.form.controls['discipline'].setValue("");
      this.disciplines = [];
      this.course_groups.forEach(element => {
        if(this.form.controls['registration_type'].value == element['group_id']) {
          this.disciplines = element['items'];
          this.selectedGroup = element;
        }
      });
    });

    this.form.controls['country'].valueChanges.pipe().subscribe(() => {
      if(this.form.controls['country'].value && this.form.controls['country'].value != 102) {
        this.corres_states = [{'id': '35', 'name': 'Others'}];
        setTimeout(() => {
          this.form.controls['state'].setValue('35');
        }, 500)
      } else {
        this.corres_states = this.states;
        setTimeout(() => {
          this.form.controls['state'].setValue(this.personalData?.state);
        }, 500)
      }
    });

    this.form.controls['permanent_country'].valueChanges.pipe().subscribe(() => {
      if(this.form.controls['permanent_country'].value && this.form.controls['permanent_country'].value != 102) {
        this.permanent_states = [{'id': '35', 'name': 'Others'}];
        setTimeout(() => {
          this.form.controls['permanent_state'].setValue('35');
        }, 500)
      } else {
        this.permanent_states = this.states;
        setTimeout(() => {
          this.form.controls['permanent_state'].setValue(this.personalData?.permanent_state || "");
        }, 500)
      }
    });

    this.form.controls['address_same'].valueChanges.pipe().subscribe(() => {
      if(this.form.controls['address_same'].value == 2) {
        this.form.controls['permanent_address_line1'].setValue('');
        this.form.controls['permanent_address_line2'].setValue('');
        this.form.controls['permanent_address_line3'].setValue('');
        this.form.controls['permanent_city'].setValue('');
        this.form.controls['permanent_state'].setValue('');
        this.form.controls['permanent_country'].setValue('');
        this.form.controls['permanent_pincode'].setValue('');

        this.form.controls['permanent_address_line1'].setValidators(Validators.required);
        this.form.controls['permanent_city'].setValidators(Validators.required);
        this.form.controls['permanent_country'].setValidators(Validators.required);
        this.form.controls['permanent_state'].setValidators(Validators.required);
        this.form.controls['permanent_pincode'].setValidators(Validators.required);
      } else {
        this.form.controls['permanent_address_line1'].setValue(this.form.controls['address_line1'].value);
        this.form.controls['permanent_address_line2'].setValue(this.form.controls['address_line2'].value);
        this.form.controls['permanent_address_line3'].setValue(this.form.controls['address_line3'].value);
        this.form.controls['permanent_city'].setValue(this.form.controls['city'].value);
        this.form.controls['permanent_state'].setValue(this.form.controls['state'].value);
        this.form.controls['permanent_country'].setValue(this.form.controls['country'].value);
        this.form.controls['permanent_pincode'].setValue(this.form.controls['pincode'].value);

        this.form.controls['permanent_address_line1'].removeValidators(Validators.required);
        this.form.controls['permanent_city'].removeValidators(Validators.required);
        this.form.controls['permanent_country'].removeValidators(Validators.required);
        this.form.controls['permanent_state'].removeValidators(Validators.required);
        this.form.controls['permanent_pincode'].removeValidators(Validators.required);
      }
    });
  }

  getPersonals() {
    this.registrationService.get().subscribe((res: any) => {
      // console.log(res);
      if(res.status) {
        this.personalData = res.personal;

        for (const value of this.course_groups) {
          if(this.personalData?.registration_type == value['group_id']) {
            this.disciplines = value['items'];
            this.selectedGroup = value;
          }
        }

        let selectedCourse = this.mastersService.get_correspondings(this.disciplines, this.personalData?.discipline);

        this.headerText = 'You are applying to ' + selectedCourse['estimated_duration']  + ' Year ' + selectedCourse['description'];

        this.form.controls['registration_type'].setValue(this.personalData?.registration_type || '');
        this.form.controls['discipline'].setValue(this.personalData?.discipline || '');
        this.form.controls['salutation'].setValue(this.personalData?.salutation || '');
        this.form.controls['firstname'].setValue(this.personalData?.firstname || '');
        this.form.controls['middlename'].setValue(this.personalData?.middlename || '');
        this.form.controls['lastname'].setValue(this.personalData?.lastname || '');
        this.form.controls['dob'].setValue(this.personalData?.dob || '');
        this.form.controls['gender'].setValue(this.personalData?.gender || '');
        this.form.controls['category'].setValue(this.personalData?.category || '');
        this.form.controls['mobile'].setValue(this.personalData?.mobile || '');
        this.form.controls['email'].setValue(this.personalData?.email || '');
        this.form.controls['confirm_email'].setValue(this.personalData?.email || '');
        this.form.controls['birth_place'].setValue(this.personalData?.birth_place || '');
        this.form.controls['nationality'].setValue(this.personalData?.nationality || '');
        this.form.controls['height'].setValue(this.personalData?.height || '');
        this.form.controls['weight'].setValue(this.personalData?.weight || '');
        this.form.controls['bloodgroup'].setValue(this.personalData?.bloodgroup || '');
        this.form.controls['guardian_name'].setValue(this.personalData?.guardian_name || '');
        this.form.controls['guardian_relation'].setValue(this.personalData?.guardian_relation || '');
        this.form.controls['guardian_income'].setValue(this.personalData?.guardian_income || '');
        this.form.controls['guardian_profession'].setValue(this.personalData?.guardian_profession || '');
        this.form.controls['guardian_designation'].setValue(this.personalData?.guardian_designation || '');
        this.form.controls['address_line1'].setValue(this.personalData?.address_line1 || '');
        this.form.controls['address_line2'].setValue(this.personalData?.address_line2 || '');
        this.form.controls['address_line3'].setValue(this.personalData?.address_line3 || '');
        this.form.controls['city'].setValue(this.personalData?.city || '');
        this.form.controls['state'].setValue(this.personalData?.state || '');
        this.form.controls['country'].setValue(this.personalData?.country || '');
        this.form.controls['pincode'].setValue(this.personalData?.pincode || '');
        this.form.controls['phonenumber'].setValue(this.personalData?.phonenumber || '');
        this.form.controls['address_same'].setValue(this.personalData?.address_same || '1');
        this.form.controls['permanent_address_line1'].setValue(this.personalData?.permanent_address_line1 || '');
        this.form.controls['permanent_address_line2'].setValue(this.personalData?.permanent_address_line2 || '');
        this.form.controls['permanent_address_line3'].setValue(this.personalData?.permanent_address_line3 || '');
        this.form.controls['permanent_city'].setValue(this.personalData?.permanent_city || '');
        this.form.controls['permanent_state'].setValue(this.personalData?.permanent_state || '');
        this.form.controls['permanent_country'].setValue(this.personalData?.permanent_country || '');
        this.form.controls['permanent_pincode'].setValue(this.personalData?.permanent_pincode || '');
        this.form.controls['education1_school'].setValue(this.personalData?.education1_school || '');
        this.form.controls['education1_board'].setValue(this.personalData?.education1_board || '');
        this.form.controls['education1_place'].setValue(this.personalData?.education1_place || '');
        this.form.controls['education1_subject'].setValue(this.personalData?.education1_subject || '');
        this.form.controls['education1_yop'].setValue(this.personalData?.education1_yop || '');
        this.form.controls['education1_percent'].setValue(this.personalData?.education1_percent || '');
        this.form.controls['education2_school'].setValue(this.personalData?.education2_school || '');
        this.form.controls['education2_stream'].setValue(this.personalData?.education2_stream || '');
        this.form.controls['education2_board'].setValue(this.personalData?.education2_board || '');
        this.form.controls['education2_place'].setValue(this.personalData?.education2_place || '');
        this.form.controls['education2_subject'].setValue(this.personalData?.education2_subject || '');
        this.form.controls['education2_status'].setValue(this.personalData?.education2_status || '');
        this.form.controls['education2_exam'].setValue(this.personalData?.education2_exam || '');
        this.form.controls['education2_explain'].setValue(this.personalData?.education2_explain || '');
        this.form.controls['website_lead'].setValue(this.personalData?.website_lead || '');
        this.form.controls['newspaper_lead'].setValue(this.personalData?.newspaper_lead || '');
        this.form.controls['wom_lead'].setValue(this.personalData?.wom_lead || '');
        this.form.controls['coaching_lead'].setValue(this.personalData?.coaching_lead || '');
        this.form.controls['other_lead'].setValue(this.personalData?.other_lead || '');
      
        if(this.personalData.profile_photo) {
          this.profilePicPreview = environment.apiUrl + "/uploads/registrations/" + this.personalData.registrationid + "/" + this.personalData.profile_photo;
        }

        if(this.personalData.sign_photo) {
          this.SignPicPreview = environment.apiUrl + "/uploads/registrations/" + this.personalData.registrationid + "/" + this.personalData.sign_photo;
        }
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  getMasters() {
    this.mastersService.read().subscribe((res: any) => {
      if(res.status) {
        this.masters = res.data;
        this.salutations = this.masters.salutations;
        this.genders = this.masters.genders;
        this.nations = this.masters.nations;
        this.genders = this.masters.genders;
        this.blood_groups = this.masters.blood_groups;
        this.annualincomes = this.masters.annual_income;
        this.relations = this.masters.relations;
        this.professions = this.masters.professions;
        this.states = this.masters.states;
        this.boards = this.masters.boards;
        this.categories = this.masters.categories;
        this.course_groups = this.masters.course_groups;
        this.getPersonals();
      }
     }, (err: any) => {
      this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
      // console.log(err);
    });
  }

  submit(event: any) {
    this.submitted = true;
    if(this.currentUser.is_submitted == 0) {
      if (this.form.valid) {
        this.isPersonalLoading = true;
        let data = this.form.value;
        if(this.SignPicObject) {
          data['sign_photo'] = this.SignPicObject;
        }
        if(this.profilePicObject) {
          data['profile_photo'] = this.profilePicObject;
        }
        this.registrationService.update(data).subscribe((res: any) => {
          this.isPersonalLoading = false;
          if(res) {
            this.submitted = false;
            this.messageService.add({ severity: res.severity, detail: res.message, life: 3000 });
            if(res.status && res.statusCode == 200) {
              this.authenticationService.setCurrentUserValue(res.user_data);
              localStorage.setItem("currentUser", JSON.stringify(res.user_data));
              if(event.submitter.id == "saveAndcontinue" && res?.user_data?.is_personal_completed == 1) {
                setTimeout(() => {
                  this.router.navigate(['/upload-document']);
                }, 1000);
              }
            }
          } else {
            this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
          }
         }, (err: any) => {
          this.isPersonalLoading = false;
          this.messageService.add({ severity: 'error', detail: 'Internal Server Error, Please Try Again Later.', life: 3000 });
          // console.log(err);
        });
      } else {
        this.messageService.add({ severity: 'error', detail: 'Please Check all Mandatory Fields to Submit', life: 3000 });
      }
    } else {
      this.messageService.add({ severity: 'error', detail: 'Not Allowed to perform action, Application Already Submitted.', life: 3000 });
    }
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }
}
